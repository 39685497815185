import Vue from 'vue';
import App from './App.vue';
import AppForAutoCheckout from './AppForAutoCheckout.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store/store';
import './assets/formulate.css';
import VueSocialSharing from 'vue-social-sharing';
import VueMatomo from 'vue-matomo';
import VueMeta from 'vue-meta';

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;

import { Auth0Plugin } from "./auth";
import {isProduction} from "@/utility";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  cacheLocation: 'localstorage',
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname,
    );
  },
});

Vue.use(VueSocialSharing);
Vue.config.productionTip = false;

Vue.use(VueMatomo, {
  host: "https://learningplus.matomo.cloud/",
  siteId: 1,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: true,
  requireCookieConsent: true,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: process.env.NODE_ENV == 'development' ? true : false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
});

Vue.use(VueMeta);

Vue.mixin({
  methods: {
    matomoEvent(category, action, name, value) {
      if (isProduction() && this.$matomo) {
         this.$matomo.trackEvent(category, action, name, value);
      }
    },
  },
});

new Vue({
  router,
  vuetify,
  store,
  render: h => {
    if (window.location.pathname === '/checkout') {
      return h(AppForAutoCheckout);
    }
    return h(App);
  },
}).$mount('#app');

<template>
  <div class="px-3">

    <v-card-title class="pt-0">
      <v-icon class="pr-2">
        mdi-shopping-outline
      </v-icon>

      Your Basket:
      <span class="primary--text font-weight-black">
        &nbsp;{{basket.length}}
      </span>

      &nbsp;Course(s)
    </v-card-title>

    <div v-for="item in basket" :key="item._id">
      <v-card class="mb-3 rounded-xl pa-2" outlined>
        <v-row>
          <div class="pa-3 item-img"
               v-if="item.image">
            <v-badge
                offset-y="20"
                overlap
                color="primary"
                :content="item.quantity"
            >
              <picture>
                <img
                    :src="item.image.url"
                    :alt="item.image.alt"
                    :width="imageWidth"
                    :height="imageheight"
                    class="rounded-xl cover"
                />
              </picture>
            </v-badge>
          </div>

          <div class="pa-3 pb-0"
               v-else
          >
            <v-badge
                offset-y="20"
                overlap
                color="primary"
                :content="item.quantity"
            >
              <div class="pb-0">
                <picture>
                  <img
                      src="/images/categories/pp-default.jpg"
                      alt="LearningPlus Course"
                      :width="imageWidth"
                      :height="imageheight"
                      class="rounded-xl cover"
                  />
                </picture>
              </div>
            </v-badge>
          </div>

          <v-col>
            <v-list-item class="pa-0">
              <v-list-item-content class="pa-0">
                <div class="secondary--text font-weight-bold text-md-h6 text-left">
                  {{ item.name }}
                </div>

                <div v-if="item.level"
                     class="text-subtitle-2"
                >
                  Level: {{ item.level.name }}
                </div>

                <div class="secondary--text text-caption">
                    &pound;{{ unitsToDecimal(item.priceInUnits) }} inc VAT
                </div>

                <div class="text-h7 font-weight-medium primary--text mr-2 hidden-md-and-up">
                  &pound;{{ unitsToDecimal(item.priceInUnits * item.quantity) }}
                </div>

                <div class="text-h7 font-weight-medium primary--text mr-2 hidden-sm-and-down">
                  &pound;{{ unitsToDecimal(item.priceInUnits * item.quantity) }}
                </div>

                <v-sheet height="50">
                  <BuyMultiple :item="item"/>
                </v-sheet>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <v-icon
            @click="removeItem(item)"
            class="remove-basket"
        >
          mdi-trash-can-outline
        </v-icon>
      </v-card>
    </div>

    <div v-if="showBusinessOffer">
      <BusinessOffer/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BuyMultiple from "@/components/basket/BuyMultiple";
import {unitsToDecimal} from "@/utility";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";
import BusinessOffer from "@/components/basket/BusinessOffer";

export default {
  name: "BasketItem",
  components: {BuyMultiple, BusinessOffer},
  mixins: [matomoEcommerce],
  computed: {
    ...mapGetters(['basket', 'basketValue', 'showBusinessOffer']),
    imageWidth () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 100;
      }
      return 150;
    },
    imageheight () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 90;
      }
      return 110;
    },
  },
  methods: {
    ...mapActions([ 'deleteBasketItem']),
    unitsToDecimal: unitsToDecimal,
    removeItem(item) {
      this.deleteBasketItem(item);
      this.matomoRemoveEcommerceItem(item._id);
      this.matomoTrackEcommerceCartUpdate(this.basketValue);
    },
  },
};
</script>

<style scoped>
.v-responsive__sizer {
  padding-bottom: 0 !important;
}

.remove-basket {
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.item-img {
  height: 110px !important;
}
</style>

<template>
  <v-col>
    <Compliance></Compliance>
  </v-col>
</template>

<script>
import Compliance from "./Compliance";
export default {
name: "Column1",
  components: {Compliance},
};
</script>

<style scoped>

</style>

const state = {
    visibleSearchResults: false,
    searchResults: [],
    searchResultsLoading: false,
    searchValue: null,
    searchResultsPagination: null,
};

const getters = {
    visibleSearchResults: state => {
        return state.visibleSearchResults;
    },
    searchResults: state => {
        return state.searchResults;
    },
    searchResultsLoading: state => {
        return state.searchResultsLoading;
    },
    searchValue: state => {
      return state.searchValue;
    },
    searchResultsPagination: state => {
        return state.searchResultsPagination;
    },
};

const actions = {
    hideSearchResults(context){
        context.commit('hideSearchResults');
    },
    clearSearchResults(context){
        context.commit('clearSearchResults');
    },
    searchCourses(context, {search = String, inputOptions = {}}) {

        const trimmedVal = search.trim();

        context.commit('hideSearchResults');
        context.commit('setSearchValue', trimmedVal);

        if (!trimmedVal) {
            return;
        }

        const regex = {"$regex": trimmedVal, "$options": 'i'};

        context.commit('setSearchResultsLoading', true);

        context.dispatch('retrieveCoursesFromApi', {
            searchCriteria: {
                filter: {
                    name: regex,
                },
            },
            inputOptions,
        }).then(response => {
            context.commit('setSearchResults', response);
            context.commit('setSearchResultsLoading', false);
            context.commit('showSearchResults');
        });
    },
};

const mutations = {
    showSearchResults(state){
        state.visibleSearchResults = true;
    },
    clearSearchResults(state){
        state.searchResults = [];
        state.searchValue = null;
        state.visibleSearchResults = false;
        state.searchResultsPagination = null;
    },
    hideSearchResults(state){
        state.visibleSearchResults = false;
    },
    setSearchValue(state, search){
        state.searchValue = search;
    },
    setSearchResults(state, results){
        state.searchResults = results.docs;
        state.searchResultsPagination = {
            page: results.page,
            totalPages: results.totalPages,
        };
    },
    setSearchResultsLoading(state, isLoading){
        state.searchResultsLoading = isLoading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

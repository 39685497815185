const state = {
    frequentlyAskedQuestions: [
        {
            title: "I’ve completed my Level 1 Health and Safety in a Construction Environment course. Can I have my certificate so that I can apply for my CSCS card?",
            text: "In order to receive your certificate, you will need to pass the final assessment. We can schedule the exam Mon-Fri between 9am and 5pm but we need at least 3 working days' notice. You will need to tell us your preferred date and time slot and provide us with a photo of your ID document.",
        },
        {
            title: "How long does it take to get my exam result?",
            text: "Exam results are usually sent within 24 hours. If you have not received your certificate within 24 hours, please contact us.",
        },
        {
            title: "How long does it take to receive my Level 1 Health and Safety in a Construction Environment exam certificate?",
            text: "The certificates can take up to 10 working days to be received but are usually received much sooner.",
        },
        {
            title: "I need to order a number of courses for my staff/colleagues.",
            text: "If you need to purchase more than one course for staff/colleagues, please purchase them as a business user. When purchasing, you will need to provide their names and email addresses so that we can email them their login details. If purchasing 10 or more courses, please contact us to see if any discounts are available.",
        },
        {
            title: "Can I use LearningPlus as a Learning Management System?",
            text: "The LearningPlus platform makes for a great LMS, allowing you to allocate courses to your staff/colleagues, but also allows you to host internal practices and procedures as eLearning content. To learn more, please contact one of the team.",
        },
        {
            title: "How long is the Level 1 Health and Safety in a Construction Environment exam?",
            text: "You are given 60 minutes to complete the exam. Most people don’t require the whole hour, so you should have plenty of time to complete the exam, whilst considering each answer carefully.",
        },
        {
            title: "What is the pass mark for the Level 1 Health and Safety in a Construction Environment exam?",
            text: "You need to score 80% on the exam to pass - there are 40 questions.",
        },
        {
            title: "What do I need to apply for my CSCS card?",
            text: "To apply for your CSCS card, you will need to sit, and pass, both the Level 1 Health and Safety in a Construction Environment (or CITB/CSCS Approved alternative), and the CITB Health, Safety and Environment test. Once you have both pass certificates you can apply directly to CSCS (https://www.cscs.uk.com/applying-for-cards/).",
        },
        {
            title: "When will I receive my course certificate?",
            text: "Once you have completed the learning materials, you will immediately be able to download your certificate. If an accredited course has a final assessment, you will need to complete that before receiving your full certificate. Many certificates are available instantly, though some can take up to 10 working days, whilst the awarding body prepares them (though it is usually much quicker).",
        },
    ],
};
const getters = {
    frequentlyAskedQuestions: state => {
        return state.frequentlyAskedQuestions;
    },
};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
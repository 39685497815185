<template>
  <v-card class="pa-5 my-5 rounded-xl">

    <v-row>
      <v-col cols="5" class="hidden-xs-only">
        <picture>
          <img
              src="/images/shades/business-offer.jpg"
              alt="LearningPlus for Business Offer"
              height="200"
              class="my-4 contain"
              width="100%"
          />
        </picture>
      </v-col>

      <v-col>
        <v-card-title class="primary--text pa-0">Good news!</v-card-title>
        <div class="text-subtitle-2">
          <p>
            Due to purchasing multiple licences you could be eligible for a LearningPlus for Business account.
          </p>

          <p>
            <v-icon color="primary">
              mdi-check
            </v-icon>
            Discount and loyalty options for business accounts
          </p>

          <p>
            <v-icon color="primary">
              mdi-check
            </v-icon>
            Dedicated account manager support
          </p>

          <p>
            <v-icon color="primary">
              mdi-check
            </v-icon>
            Access to LMS Learner Management System
          </p>
        </div>

      </v-col>
    </v-row>
    <div class="text-center">
      <ContactUsButton  />
    </div>
  </v-card>
</template>

<script>
import ContactUsButton from "../buttons/ContactUsButton";
export default {
  name: "BusinessOffer",
  components: {ContactUsButton},
};
</script>

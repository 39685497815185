import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {
    retries: 5,
    retryDelay: (retryCount) => {
        return retryCount * 500;
    },
});

export default axios;
<template>
  <v-container fluid class="ma-0 pa-0 hd">
      <div class="navbar">
        <MobileHeader></MobileHeader>
        <MediumHeader></MediumHeader>
        <ProfileNudge />
      </div>
  </v-container>
</template>

<script>
import MobileHeader from "@/components/header/MobileHeader";
import MediumHeader from "@/components/header/MediumHeader";
import ProfileNudge from "@/components/common/ProfileNudge";


export default {
  name: "Header",
  components: {MediumHeader, MobileHeader, ProfileNudge},
};

</script>

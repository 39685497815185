<template>
  <div>
    <v-divider dark></v-divider>
    <span class=" ma-4 ma-md-10 text-body-2 white--text">
      <EditableText handle="footer-copyright" v-model="contentItem" />
    </span>
  </div>
</template>

<script>
import EditableText from "../EditableText";
import store from "../../store/store";

export default {
  name: "Copyright",
  components: {EditableText},
  computed: {
    contentItem: {
      get() {
        return store.getters.content('footer-copyright');
      },
    },
  },
};
</script>
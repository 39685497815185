import { isProduction } from '../utility.js';

export function initialiseAnalytics (router) {
    /* Only run in production mode */
    if (!isProduction()) return;

    let script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-VLWBMQ2HLL';
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-VLWBMQ2HLL');

    let set = page => {
        window.gtag('config', 'G-VLWBMQ2HLL', {
            page_path: page,
        });
    };

    router.afterEach((to) => {
        set(to.fullPath);
    });
}

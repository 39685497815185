<template>
  <v-row class="mt-2" v-if="$auth.isAuthenticated && hasRole($auth.user, ['LearningPlusUser'])">
    <v-col cols="12" md="8" lg="6" xl="5" class="profilenudge">
      <v-alert
          v-model="showProfileNudge"
          color="black"
          class="pa-5 pa-sm-3 ml-3">
        <v-row align="center">
          <v-col class="grow py-0 white--text" cols="12" lg="8" sm="8" md="7">
            <span class="hidden-xs-only">Welcome!</span>
            Please complete your LearningPlus Profile.
          </v-col>
          <v-spacer />
          <v-col class="shrink">
            <v-btn width="140" color="black">
              <v-btn
                  to="/profile" small
                  class="rounded-xl pa-4 pp-scale-1" color="primary">
              <span class="text-subtitle-2">
              Complete Profile
          </span>
              </v-btn>
            </v-btn>
          </v-col>
        </v-row>
        <v-sheet class="hidden-sm-and-up" height="30" color="transparent" />
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {hasRoleMixin} from "@/mixin/hasRoleMixin";

export default {
  name: "ProfileNudge",
  mixins: [hasRoleMixin],
  computed: {
    ...mapGetters(['showProfileNudge']),
  },
};
</script>

<style scoped>
.profilenudge {
  width: 100%;
  z-index: 90;
  font-size: 14px;
  right: 10px !important;
  top: 45px;
  margin-top: 15px;
  padding: 6px;
  position: fixed;
  background-color: transparent;
}

@media screen and (max-width: 600px) {
  .profilenudge {
    top: 80vh;
  }
}
</style>

import router from "./router";

export function unitsToDecimal(units) {
    return (units / 100).toFixed(2).replace(/\.00/,'');
}

export function navigateCourse(course) {
    this.setCourseSelected(course);
    router.push({path: '/course/' + course.slug});
    this.matomoEvent('View Course', 'View Course Click', 'View Course');
    this.matomoSetEcommerceView(course.id, course.name, "", course.priceInUnits);
}

export function isProduction() {
    // return true;
    // Comment out for testing
    return (process.env.NODE_ENV === 'production');
}

export const resultsPerPage = 40;

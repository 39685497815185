<template>
  <div v-if="!hasGivenConsent">
    <div class="cookie-banner">
      <v-row>
        <v-col cols="12" md="8">
          <p class="text-h6 font-weight-bold">
            <v-icon color="white">mdi-lock</v-icon>
            Your Privacy
          </p>
          <p class="text-h7">
            We use cookies to help give you the best experience on our site and to allow us and third parties to
            customise the marketing content you see across websites and social media. For more information see our
            <a
                class="cookie-link font-weight-bold"
                rel="noopener noreferrer"
                :href="cookieURL"
            >
              Cookie Policy.
            </a>
          </p>
        </v-col>

        <v-col cols="12" md="4">
          <button
              id="functionalCookies"
              class="accept-btn pp-scale-1 pp-full-width"
              title="Only use functional cookies"
              aria-label="Only use functional cookies"
              @click="handleConsent"
          >
            Only use functional cookies
          </button>

          <button
              id="allCookies"
              class="privacy-btn accept-btn pp-scale-1 pp-full-width"
              @click="handleConsent"
              title="Allow all cookies"
              aria-label="Allow all cookies"
          >
            Allow all cookies
          </button>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { initialiseAnalytics } from "../../plugins/google-analytics";

export default {
  name: "Cookie",
  computed: {
    ...mapGetters(["hasGivenConsent", "getConsentGiven"]),
  },
  data: () => ({
    cookieURL: process.env.VUE_APP_PRIVACY_POLICY,
    marketingScriptInstalled: false,
    analyticsInitialised: false,
    linkedInScriptInstalled: false,
    metaPixelScriptInstalled: false,
  }),
  methods: {
    ...mapActions(["setConsentGiven"]),
    handleConsent(e) {
      if (e.target.id === "allCookies") {
        this.setConsentGiven('all');
        this.$matomo.rememberCookieConsentGiven();
        this.$matomo.rememberConsentGiven();
        this.installMetaPixel();
        initialiseAnalytics(this.$router);
      } else {
        this.setConsentGiven('functional');
      }
    },

    installMetaPixel() {
      if (this.metaPixelScriptInstalled || this.getConsentGiven !== 'all') {
        return;
      }
      const script = document.createElement("script");
      script.innerHTML = `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '3493215464294962');
      fbq('track', 'PageView');`;
      document.head.appendChild(script);

      const noscript = document.createElement("noscript");
      const img = document.createElement("img");
      img.height = "1";
      img.width = "1";
      img.style = "display:none";
      img.src = "https://www.facebook.com/tr?id=3493215464294962&ev=PageView&noscript=1";
      noscript.appendChild(img);
      document.body.appendChild(noscript);

      this.metaPixelScriptInstalled = true;
    },

    installMarketingScript() {
      if (this.getConsentGiven !== 'all') {
        return;
      }
      if (!this.marketingScriptInstalled) {
        const script = document.createElement("script");
        script.innerText = `(function (f, o, r, c, e, _2, _4) {` +
            `f.Force24Object = e, f[e] = f[e] || function () {` +
            `f[e].q = f[e].q || [], f[e].q.push(arguments)` +
            `}, f[e].l = 1 * new Date, _2 = o.createElement(r),` +
            `_4 = o.getElementsByTagName(r)[0], _2.async = !0, _2.src = c, _4.parentNode.insertBefore(_2, _4)` +
            `})(window, document, "script", "https://static.websites.data-crypt.com/scripts/activity/v3/inject-v3.min.js", "f24");` +
            `f24('config', 'set_tracking_id', '${process.env.VUE_APP_FORCE24_TRACKING_ID}');` +
            `f24('config', 'set_client_id', '${process.env.VUE_APP_FORCE24_CLIENT_ID}');`;
        document.head.appendChild(script);
        this.marketingScriptInstalled = true;
      }
      if (!this.linkedInScriptInstalled) {
        this.installLinkedInScript();
      }
    },

    initialiseAnalytics() {
      if (this.analyticsInitialised || this.getConsentGiven !== 'all') {
        return;
      }
      initialiseAnalytics(this.$router);
      this.analyticsInitialised = true;
    },

    installLinkedInScript() {
      window._linkedin_partner_id = "1360561";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

      const s = document.getElementsByTagName("script")[0];
      const b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);

      const img = document.createElement("img");
      img.height = "1";
      img.width = "1";
      img.style.display = "none";
      img.alt = "";
      img.src = "https://px.ads.linkedin.com/collect/?pid=1360561&fmt=gif";
      document.body.appendChild(img);

      this.linkedInScriptInstalled = true;
    },
  },

  mounted() {
    this.installMarketingScript();
    this.installMetaPixel();
    initialiseAnalytics(this.$router);
  },
  beforeUpdate() {
    this.installMarketingScript();
    this.installMetaPixel();
    this.initialiseAnalytics();
  },
};
</script>

<style scoped>
.cookie-banner {
  background-color: rgb(47, 51, 55);
  color: white;
  padding: 2.5rem;
  box-shadow: rgba(0, 0, 0, 0.28) 0 8px 28px !important;
  border-radius: 2rem;
  position: fixed;
  bottom: 1.3rem;
  margin: 1rem 3rem 0 1rem;
  z-index: 2000;
}

.cookie-link {
  color: white;
}

.cookie-link:hover {
  font-weight: bold;
  text-decoration: underline;
}

.accept-btn {
  background-color: var(--primary);
  border-radius: 30px;
  padding: 10px 40px;
  font-weight: bold;
  margin: 10px;
}

.privacy-btn {
  background-color: var(--secondary);
}
</style>

<template>
  <v-container class="ma-0 pa-0 ma-md-4">
    <v-card-title class="pp-footer-title px-0 ">
      Get in touch
    </v-card-title>

    <address class="pp-address">
      LearningPlus<br/>
      4th Floor, Edmund House,<br/>
      Newhall Street, Birmingham, B3 3AS
    </address>

    <v-row>
      <v-btn outlined
             rounded
             class="text-sm-body-1 text-body-2 text-lowercase ma-2 pp-scale font-weight-regular"
             color="white"
             :href="supportEmail.href"
             :title="supportEmail.title"
             :aria-labelledby="supportEmail.linkText"
             @click="matomoEvent('Contact Support', 'Contact Support Click', 'Contact Support Email');"
      >
        <v-icon class="mr-2">
          mdi-email-outline
        </v-icon>
        {{ supportEmail.email }}
      </v-btn>
    </v-row>

    <v-row>
      <a title="PeoplePlus Facebook"
         aria-labelledby="View PeoplePlus Facebook"
         href="https://www.facebook.com/PeoplePlusUK"
         target="_blank"
         rel="noopener noreferrer"
         @click="matomoEvent('Social Media', 'Social Media Click', 'Visit Facebook Page');"
      >
        <v-icon class="white--text my-5 mr-10" large>
          mdi-facebook
        </v-icon>
      </a>

      <a title="PeoplePlus LinkedIn"
         aria-labelledby="View PeoplePlus LinkedIn"
          href="https://www.linkedin.com/company/peopleplusuk"
         target="_blank"
         rel="noopener noreferrer"
         @click="matomoEvent('Social Media', 'Social Media Click', 'Visit LinkedIn Page');"
      >
        <v-icon class="white--text my-5 mr-10" large>
          mdi-linkedin
        </v-icon>
      </a>

      <a href="https://twitter.com/peopleplusuk"
         title="PeoplePlus Twitter"
         aria-labelledby="View PeoplePlus Twitter"
         target="_blank"
         rel="noopener noreferrer"
         @click="matomoEvent('Social Media', 'Social Media Click', 'Visit Twitter Page');"
      >
        <v-icon class="white--text my-5" large>
          mdi-twitter
        </v-icon>
      </a>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "Contacts",
  computed: {
    ...mapGetters(['supportEmail']),
  },
};
</script>

<style scoped>
.pp-address {
  color: white;
  padding-bottom: 25px;
  font-style: normal;

}
</style>

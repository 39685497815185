const state = {
    profile: {
        learnerUser:
            {
                src: '/images/logo/logo.svg',
                alt: 'LearningPlus Logo',
                height: '35',
                title: 'LearningPlus Portal',
                text: 'Access your courses, notifications and resources',
                path: process.env.VUE_APP_LEARNER_PORTAL,
            },
        businessUser:
            {
                src: '/images/logo/pp-logo-business.svg',
                alt: 'LearningPlus Logo',
                height: '35',
                title: 'LearningPlus Business Reports',
                text: 'View your business reports',
                path: process.env.VUE_APP_BUSINESS_REPORT,
            },
        businessPPS:
            {
                src: '/images/logo/peopleplus.svg',
                alt: 'LearningPlus Logo',
                height: '40',
                title: 'PeoplePlus System',
                text: 'Manage your learners on PPS',
                path: process.env.VUE_APP_PPS,
            },
    },
};

const getters = {
    profileLearnerUser: state => {
        return state.profile.learnerUser;
    },
    profileBusinessUser: state => {
        return state.profile.businessUser;
    },
    profileBusinessPPS: state => {
        return state.profile.businessPPS;
    },
};
const actions = {

};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};

const state = {
    consentGiven: false,
};

const getters = {
    hasGivenConsent: state => {
        return state.consentGiven ? true : false;
    },
    getConsentGiven: state => {
        return state.consentGiven;
    },
};

const actions = {
    setConsentGiven(context, hasConsented) {
        context.commit('setConsentGiven', hasConsented);
    },
};

const mutations = {
    setConsentGiven(state, value) {
        state.consentGiven = value;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

import {isProduction} from "@/utility";

export const matomoEcommerce = {
    methods: {
        matomoAddBasket(item, basketValue) {
            this.matomoSetEcommerceView(item._id, item.name, "", item.priceInUnits);
            this.matomoAddEcommerceItem(item._id, item.name,"", item.priceInUnits, item.quantity);
            this.matomoTrackEcommerceCartUpdate(basketValue);
        },
        matomoSetEcommerceView(productSKU, productName, categoryName, price) {
            if (isProduction() && this.$matomo) {
                this.$matomo.setEcommerceView(productSKU, productName, categoryName, (parseFloat(price) / 100));
            }
        },
        matomoAddEcommerceItem(productSKU, productName, categoryName, price, quantity) {
            if (isProduction() && this.$matomo) {
                this.$matomo.addEcommerceItem(productSKU, productName, categoryName, (parseFloat(price) / 100), quantity);
            }
        },
        matomoRemoveEcommerceItem(productSKU) {
            if (isProduction() && this.$matomo) {
                this.$matomo.removeEcommerceItem(productSKU);
            }
        },
        matomoTrackEcommerceOrder(orderId, grandTotal, subTotal, tax, shipping, discount) {
            if (isProduction() && this.$matomo) {
                this.$matomo.trackEcommerceOrder(orderId, (parseFloat(grandTotal) / 100), subTotal, tax, shipping, discount);
            }
        },
        matomoTrackEcommerceCartUpdate(basketValue) {
            if (isProduction() && this.$matomo) {
                this.$matomo.trackEcommerceCartUpdate(parseFloat(basketValue) / 100);
            }
        },
    },
};

import axios from './common';

const state = {
    userProfile: {},
    editableUserProfile: {
        given_name: undefined,
        family_name: undefined,
    },
    profileMetadata: {
        business_name: undefined,
        business_industry: undefined,
        business_location: undefined,
        business_sectors: [],
        business_sector_other: undefined,
        business_volume: undefined,
        contact_number: undefined,
        course_amount: undefined,
        frequency: undefined,
        marketing: undefined,
        number_of_employees: undefined,
        postcode: undefined,
        purchase_date: undefined,
        profile_updated_count: 0,
    },
    userProfileLoading: false,
    showProfileNudge: false,
};

const getters = {
    editableUserProfile: state => {
        return state.editableUserProfile;
    },
    profileMetadata: state => {
        return state.profileMetadata;
    },
    showProfileNudge: state => {
        return state.showProfileNudge;
    },
    userProfile: state => {
        return state.userProfile;
    },
    userProfileLoading: state => {
        return state.userProfileLoading;
    },
    isAuth0Profile: state => {
        return state.userProfile.user_id ? state.userProfile.user_id.startsWith('auth0') : false;
    },
};

const actions = {
    async putUserProfile(context) {

        const dataToPost = {...context.state.profileMetadata};
        dataToPost.profile_updated_count++;
        for (const [key, value] of Object.entries(dataToPost)) {
            if (key == 'marketing') {
                continue;
            }
            if (!value || value.length == 0) {
                delete dataToPost[key];
            }
        }

        const userData = {
            user_metadata: dataToPost,
        };

        if (context.getters.isAuth0Profile) {
            Object.assign(userData, {...context.state.editableUserProfile});
        }

        context.commit('setUserProfileLoading', true);

        return axios.put(`${process.env.VUE_APP_SERVICE_URI_AUTH}/api/users`, userData)
            .then(response => {
                context.commit('setUserProfileLoading', false);
                context.commit('setUserProfile', response.data);
            });
    },
    async getUserProfile(context) {

        context.commit('setUserProfileLoading', true);

        axios.get(`${process.env.VUE_APP_SERVICE_URI_AUTH}/api/users`)
            .then(response => {
                context.commit('setUserProfileLoading', false);
                context.commit('setUserProfile', response.data);
            });
    },
    updateProfile(context, val) {
        context.commit('updateEditableProfile', val);
    },
    updateProfileMetadata(context, val) {
        context.commit('updateProfileMetadata', val);
    },
};

const mutations = {
    updateEditableProfile(state, val) {
        state.editableUserProfile = Object.assign(state.editableUserProfile, val);
    },
    updateProfileMetadata(state, val) {
        state.profileMetadata = Object.assign(state.profileMetadata, val);
    },
    setUserProfile(state, profileData) {
        state.userProfile = profileData;
        state.editableUserProfile.given_name = profileData.given_name;
        state.editableUserProfile.family_name = profileData.family_name;
        state.profileMetadata = Object.assign(state.profileMetadata, profileData.user_metadata);
        if (!state.profileMetadata.marketing) {
            state.showProfileNudge = true;
            state.profileMetadata = Object.assign(
                state.profileMetadata,
                {marketing: ['text', 'email', 'phone_call']},
            );
        } else {
            state.showProfileNudge = false;
        }
    },
    setUserProfileLoading(state, loading) {
        state.userProfileLoading = loading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

<template>
  <v-col class="pa-0 pt-md-10 pl-md-10">
    <SocialLinks />
  </v-col>
</template>

<script>
import SocialLinks from "./SocialLinks";
export default {
  name: "Column2",
  components: {SocialLinks},
};
</script>

<style scoped>

</style>

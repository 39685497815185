<template>
  <v-container fluid class="pa-0 ma-0">
    <hooper :settings="hooperLogoSettings">
      <slide
          v-for="(logo, i) in trustedLogoImages"
          :key="i"
          cols="8"
      >
        <picture>
          <img
              :src="logo.url"
              :alt="logo.alt"
              height="80"
              width="100%"
              class="mt-15 pp-grayscale contain"
          />
        </picture>
      </slide>
      <hooper-navigation slot="hooper-addons"></hooper-navigation>
    </hooper>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";
import { Hooper, Slide,
  Navigation as HooperNavigation} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: "LogosMobile",

  computed: {
    ...mapGetters(['trustedLogoImages']),
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data () {
    return {
      hooperLogoSettings: {
        itemsToShow: 1.5,
        infiniteScroll: true,
        mouseDrag: true,
        touchDrag: true,
        wheelControl: false,
        keysControl: false,
        shortDrag: false,
        transition: 500,
        hoverPause: false,
        autoPlay: true,
        playSpeed: 2000,
      },
    };
  },
};
</script>
<style scoped>
.mt-7 {
  position: relative;
  left: -20px;
}

</style>

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from "vuex-persist";

import AboutBusiness from './modules/aboutUsBusiness';
import AboutLearningPlus from './modules/aboutLearningPlus';
import Auth0 from './modules/auth0';
import AwardingBodies from './modules/awardingBodies';
import Basket from './modules/basket';
import Categories from './modules/categories';
import CategoryCourses from './modules/categoryCourses';
import CMS from './modules/cms';
import CompanyStructure from "./modules/CompanyStructure";
import Courses from './modules/courses';
import Cookies from './modules/cookies';
import FrequentlyAskedQuestions from "./modules/frequentlyAskedQuestions";
import MarketingNumbers from './modules/marketingNumbers';
import Policies from './modules/policies.js';
import Profile from './modules/profile.js';
import Reviews from './modules/reviews';
import Search from './modules/search';
import SocialMedia from './modules/socialMedia';
import Support from './modules/supportEmail';
import TrustedLogos from './modules/trustedLogos';
import UserProfile from './modules/userProfile';
import UserSteps from './modules/userSteps';
import UserTypeContent from './modules/userTypeContent';
import { version } from '../../package.json';

const VuexPersit = new VuexPersistence({
    key: 'learningPlusShop',
    storage: window.localStorage,
    reducer: (state) => ({
        Basket: state.Basket,
        Cookies: state.Cookies,
    }),
});

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [VuexPersit.plugin],
    /*
        Vuex manages a single state tree – this single object includes all your application-level state
        and serves as the “single source of truth”.
        This also means usually, you will have only one store for each application.
     */
    state: {},

    /*
        Vuex allows us to define “getters” in the store. They work as a computed property for stores.
        The same computed properties, a getter’s outcome is cached based on its dependencies, and will
        solely re-evaluate when some of its dependencies have changed.
     */
    getters: {
        appVersion: () => {
            return `v${version}`;
        },
    },

    /*
        As the name suggests it is responsible for mutating the state of the store object, we can easily
        update Vuex state. The exclusive approach to actually update state in a Vuex store is by performing
        a mutation. Vuex mutations are related to events: each mutation has a string type and a handler.
     */
    mutations: {},

    /*
        The action handlers are used to make API calls that is used to perform the CRUD operations to
        manage the data for the Vuex store. Action handlers get a context object which reveals the same
        set of methods and properties on the Vuex store instance.
     */
    actions: {},

    modules: {
        AboutBusiness,
        AboutLearningPlus,
        Auth0,
        AwardingBodies,
        Basket,
        Categories,
        CategoryCourses,
        CMS,
        Cookies,
        CompanyStructure,
        Courses,
        FrequentlyAskedQuestions,
        MarketingNumbers,
        Policies,
        Profile,
        Reviews,
        Search,
        SocialMedia,
        Support,
        TrustedLogos,
        UserProfile,
        UserSteps,
        UserTypeContent,
    },
});

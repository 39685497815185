<template>
  <v-btn
      color="primary"
      fab
      dark
      @click="showBasket"
      title="View Basket"
      aria-label="View Basket"
  >
    <v-icon>
      mdi-shopping-outline
    </v-icon>

    <v-dialog
        v-model="basketVisible"
        @click:outside="hideBasket"
        :retain-focus="false"
        :width="basketWidth"
        overlay-color="accent"
        @keydown.esc="hideBasket"
    >

      <v-card color="transparent" flat>
        <Header />

        <div v-if="basket.length == 0">
          <BasketEmpty />
        </div>

        <div v-else>
          <BasketItem />
          <Total />
          <CheckOut />
        </div>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Header from "@/components/basket/Header";
import BasketItem from "@/components/basket/BasketItem";
import Total from "@/components/basket/Total";
import CheckOut from "@/components/basket/CheckOut";
import BasketEmpty from "@/components/basket/BasketEmpty";

export default {
  name: "Basket",
  components: {BasketEmpty, BasketItem, CheckOut, Total, Header},
  computed: {
    ...mapState(['basketVisible']),
    ...mapGetters(['basket', 'basketVisible']),
    basketWidth () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 320;
      }
      return 700;
    },
  },
  methods: {
    ...mapActions([ 'showBasket', 'hideBasket']),
  },
};
</script>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                a: '#b11116',
                primary: '#b11116',
                secondary: '#4f4f4f',
                accent: '#ECF0F3',
                error: '#ff0033',
            },
            dark: {
                a: '#00c9c7',
                primary: '#b11116',
                secondary: '#484848',
                accent: '#ECF0F3',
                error: '#ff0033',
            },
        },

    },
});

import axios from './common';
import {resultsPerPage} from "@/utility";

const state = {
    categories: [],
    categoriesPagination: null,
    categoriesLoading: true,
    categorySelected: null,
    categorySelectedLoading: false,
};
const getters = {
    categories: state => {
        return state.categories;
    },
    categoriesPagination: state => {
      return state.categoriesPagination;
    },
    categoriesLoading: state => {
        return state.categoriesLoading;
    },
    categorySelected: state => {
        return state.categorySelected;
    },
    categorySelectedLoading: state => {
        return state.categorySelectedLoading;
    },
};
const actions = {
    getCategories(context, {inputOptions = {}}) {

        const options = {
            tags: 'learning_plus',
            page: inputOptions.page ?? 1,
            limit: inputOptions.limit ?? resultsPerPage,
        };

        context.commit('setCategoriesLoading', true);

        // @todo Stop this from Auto loading all the results
        axios.get(`${process.env.VUE_APP_SERVICE_URI_COURSE}/api/categories`, { params: options })
            .then(response => {

                context.commit('setCategoriesLoading', false);
                context.commit('setCategories', response.data);

            });
    },
    setCategorySelected(context, payload) {
        context.commit('setCategorySelected', payload.category);
    },
    async getCategoryBySlug(context, payload) {

        let options = {
            tags: 'learning_plus',
            slug: payload,
        };

        context.commit('setCategorySelectedLoading', true);

        await axios.get(`${process.env.VUE_APP_SERVICE_URI_COURSE}/api/categories`, { params: options })
            .then(response => {

                context.commit('setCategorySelectedLoading', false);
                context.commit('setCategorySelected', response.data.docs[0]);

            });

    },
};
const mutations = {
    clearCategories(state){
        state.categories = [];
    },
    setCategories (state, categories) {
        state.categories = categories.docs;
        state.categoriesPagination = {
            page: categories.page,
            totalPages: categories.totalPages,
        };
    },
    setCategoriesLoading (state, loading) {
        state.categoriesLoading = loading;
        state.categoriesPagination = null;
    },
    setCategorySelected (state, category) {
        state.categorySelected = category;
    },
    setCategorySelectedLoading (state, loading) {
        state.categorySelectedLoading = loading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
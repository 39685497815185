import Vue from 'vue';
import VueRouter from 'vue-router';
import { authGuard } from "../auth/authGuard";
import VueMeta from 'vue-meta';
import { getInstance } from "@/auth";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  // keep the order alphabetical

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About'),
  },
  {
    path: '/business',
    name: 'Business',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "business" */ '@/views/BusinessLanding'),
    meta: {
      requiresBusinessRole: true,
    },
    beforeEnter: authGuard,
  },
  {
    path: '/category/:slug',
    name: 'Category',
    component: () => import(/* webpackChunkName: "category" */ '@/views/Category'),
  },
  {
    path: '/checkout-error',
    name: 'CheckoutError',
    component: () => import(/* webpackChunkName: "checkouterror" */ '@/views/CheckoutError'),
    beforeEnter: authGuard,
  },
  {
    path: '/course/:slug',
    name: 'Course',
    component: () => import(/* webpackChunkName: "course" */ '@/views/Course'),
  },
  {
    path: '/education-sector',
    name: 'EducationSector',
    component: () => import(/* webpackChunkName: "educationsector" */ '@/views/EducationSector'),
  },
  {
    path: '/employers',
    name: 'Employers',
    component: () => import(/* webpackChunkName: "employers" */ '@/views/Employers'),
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import(/* webpackChunkName: "faqs" */ '@/views/FAQs'),
  },
  {
    path: '/learners',
    name: 'Learners',
    component: () => import(/* webpackChunkName: "learners" */ '@/views/Learners'),
  },
  {
    path: '/policies/:policy',
    name: 'Policy Page',
    component: () => import(/* webpackChunkName: "Policy Page" */ '@/views/Policy'),
  },
  {
    path: '/checkout',
    name: 'AutoCheckout',
    component: () => import(/* webpackChunkName: "AutoCheckout" */ '@/views/AutoCheckout'),
    beforeEnter: authGuard,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile'),
    beforeEnter: authGuard,
  },
  {
    path: '/success',
    name: 'CheckoutSuccess',
    component: () => import(/* webpackChunkName: "checkoutsuccess" */ '@/views/CheckoutSuccess'),
    beforeEnter: authGuard,
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "notfound" */ '@/views/404'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {

    if (to.hash) {
      return {selector: to.hash};
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

/**
 * Extra bit of router magic, this checks if the user is logged in and is a business user. If they are it treats them
 * in a special way to ensure they get locked in to a specific look.
 */
router.beforeEach((to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is not authenticated, continue with the route
    if (!authService.isAuthenticated) {
      return next();
    }

    // The user is authenticated but they don't have roles
    const rolesKey = 'http://p3.peopleplus.truenorthit.co.uk/roles';
    if (!authService.user[rolesKey]) {
      return next();
    }

    // If the user is not a business user and they try to access '/business' give them a 404
    if (!authService.user[rolesKey].find(element => element === 'LearningPlusBusiness')) {
      if (to.meta.requiresBusinessRole) {
        return next({
          path: 'not-found',
        });
      }
      return next();
    }

    /**
     * By this time we know the user is a business user, we check to see if they are trying to access any route
     * which does not have the extra metadata, then re-route them to '/business'
     */
    if (!to.meta.requiresBusinessRole) {
      return next({
        path: '/business',
      });
    }

    // Just give them what they want
    return next();
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });

});

export default router;

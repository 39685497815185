const state = {
    cmsAvailable: false,
    cmsEnabled: false,
    content: [
        {
            handle: 'footer-copyright',
            content: '&copy; PeoplePlus Group Ltd part of Staffline Group plc. 19-20 The Triangle, NG2 Business Park, Nottingham, NG2 1AE. Registered in England & Wales. Company number: 5722765 Copyright &copy; PeoplePlus Group Limited',
        },
        {
            handle: 'footer-compliance',
            content: '<p>We are a leading skills and training business helping people to transform their lives and businesses through work, training, education and financial advice. We help people source and retain sustainable employment, whilst working with employers to develop work forces of the future.</p>',
        },
    ],
};
const getters = {
    cmsAvailable: state => {
        return state.cmsAvailable;
    },
    cmsEnabled: state => {
        return state.cmsEnabled;
    },
    content: (state) => (handle) => {

        let items = state.content.filter(content => {
            return content.handle === handle;
        });

        if(items.length === 1){
            return items[0].content;
        }

        return false;

    },
};
const actions = {
    setCMSAvailable(context) {
        context.commit('cmsAvailable', true);
    },
    toggleCMSEnabled(context, current){
        context.commit('cmsEnabled', !current);
    },
    setContentItem(context, item){
        context.commit('setContentItem', item);
    },
    saveContentItem(context, item){
        console.log('saveContentItem', context, item);
    },
};
const mutations = {
    cmsAvailable(state, available){
        state.cmsAvailable = available;
    },
    cmsEnabled(state, enabled){
        state.cmsEnabled = enabled;
    },
    setContentItem(state, item){

        let index = state.content.findIndex((content => content.handle === item.handle));

        if(Number.isInteger(index)){
            let updated = state.content;
            updated[index] = item;
            state.content = updated;
        }

    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
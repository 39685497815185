<template>
  <div>
    <v-list-item
        v-for="(policy, i) in policies"
        :key="i"
        class="pa-0"
    >
      <v-list-item-content>
        <a rel="noopener noreferrer"
           class="white--text pp-a"
           :href="policy.path"
        >
            {{ policy.title }}
        </a>
      </v-list-item-content>
    </v-list-item>
    <small class="grey--text">
      {{ appVersion }}
    </small>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'SocialLinks',
  computed: {
    ...mapGetters(['appVersion']),
  },
  data: () => ({
    policies: {
      privacyPolicy: {
        title: 'Privacy Policy',
        path: '/policies/privacy-policy',
      },
      modernSlavery: {
        title: 'Modern Slavery and Human Trafficking Policy',
        path: '/policies/modern-slavery',
      },
      cookiesPolicy: {
        title: 'Cookies Policy',
        path: '/policies/cookies-policy',
      },
      termsAndConditions: {
        title: 'Terms and conditions',
        path: '/policies/terms-and-conditions',
      },
      acceptableUsePolicy: {
        title: 'Acceptable use policy',
        path: '/policies/acceptable-use-policy',
      },
      customerComplaintsAndComplimentPolicy: {
        title: 'Customer Complaints and Compliment Policy',
        path: '/policies/complaints-and-compliments',
      },
    },
  }),
};
</script>

<style scoped>
.pp-a:hover
{
  font-weight: bold;
}

</style>

<template>
  <v-app>
    <v-main>
      <Header/>
      <router-view/>
      <v-lazy v-model="isActive"
              :options="{ threshold: .5 }"
              transition="fade-transition"
      >
        <PersonalReviews/>
      </v-lazy>
      <v-lazy v-model="isActive"
              :options="{ threshold: .5 }"
              transition="fade-transition"
      >
        <CompanyLogos/>
      </v-lazy>
      <v-lazy v-model="isActive"
              :options="{ threshold: .5 }"
              transition="fade-transition"
      >
        <Footer id="contact"/>
      </v-lazy>
    </v-main>
  </v-app>
</template>

<script>

import Footer from "@/components/Footer";
import Header from "@/components/Header";
import CompanyLogos from "@/components/CompanyLogos";
import PersonalReviews from "@/components/PersonalReviews";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  metaInfo: {
    title: 'LearningPlus Home',
    titleTemplate: '%s | Part of PeoplePlus',
    meta: [
      {charset: 'utf-8'},
      {robots: "index, follow"},
      {copyright: "PeoplePlus"},
      {description: 'Part of PeoplePlus, one of the UK’s leading employability and training providers, LearningPlus is our online learning solution. We help individuals develop their careers and assist organisations meet regulatory requirements for the delivery, certification, and management of training.'},
      {viewport: 'width=device-width, initial-scale=1'},
      {image: 'https://www.learningplus.co.uk/images/shades/splash-bg-1.jpg'},
    ],
  },

  components: {
    PersonalReviews,
    CompanyLogos,
    Header,
    Footer,
  },

  computed: {
    ...mapGetters(['auth0Token']),
  },
  methods: {
    ...mapActions([
      'getAuth',
      'getUserProfile',
      'getCourses',
      'getPopularCourses',
      'getCategories',
      'clearSearchResults',
    ]),
  },

  data: () => ({
    isActive: false,
  }),

  async created() {
    try {
      await this.getAuth();
      if (this.auth0Token) {
        await this.getUserProfile();
      }
      this.clearSearchResults();
      this.getCourses();
      this.getPopularCourses();
      this.getCategories({inputOptions: {page: 1}});
    } catch (err) {
      //TODO - Do something with the error and remove console.log
      console.error('error', err);
    }
  },
};
</script>

import axios from "./common";

const state = {
    awardingBodies: [],
    awardingBodiesLoading: null,
};

const getters = {
    awardingBodies: state => {
        return state.awardingBodies;
    },
    awardingBodiesLoading: state => {
        return state.awardingBodiesLoading;
    },
};

const actions = {
    getAwardingBodies(context, options) {

        if (!options.page) {
            options.page = 1;
        }

        context.commit('setAwardingBodiesLoading', true);
        context.commit('clearAwardingBodies');

        axios.get(`${process.env.VUE_APP_SERVICE_URI_COURSE}/api/certification-bodies`, {params: options})
            .then(response => {

                context.commit('setAwardingBodiesLoading', false);
                context.commit('setAwardingBodies', response.data);

                if (response.data && response.data.hasNextPage) {
                    options.page++;
                    actions.getAwardingBodies(context, options);
                }
            });
    },
};

const mutations = {
    setAwardingBodies (state, awardingBodies) {
        state.awardingBodies = awardingBodies.docs;
    },
    clearAwardingBodies(state){
        state.awardingBodies = [];
    },
    setAwardingBodiesLoading(state, loading) {
        state.awardingBodiesLoading = loading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

const state = {
    aboutBusiness: [
        {
            title: "Learning Management System (LMS)",
            subtitle: "Our LMS software makes it simple to manage training records and site procedures.",
            logo: "/images/about/about09.svg",
        },
        {
            title: "Build Custom Content and survey",
            subtitle: "Customise training plans for each job role, and create customer and staff surveys.",
            logo: "/images/about/about10.svg",
        },
        {
            title: "Assess Employees Standards",
            subtitle: "Assess literacy and numeracy as a benchmark standards against job roles.",
            logo: "/images/about/about11.svg",
        },
        {
            title: "Comprehensive Reporting Suite",
            subtitle: "Share self-service analytics, insights and dashboards in a variety of formats.",
            logo: "/images/about/about12.svg",
        },
        {
            title: "Future-proof your workforce",
            subtitle: "Benefit from upskilling your team by recruiting and training in-house talent.",
            logo: "/images/about/about13.svg",
        },
        {
            title: "Secure Login",
            subtitle: "Keep it simple within one secure login to maintain the security, availability, and confidentiality in the system.",
            logo: "/images/about/about14.svg",
        },
        {
            title: "Support remote workforce",
            subtitle: "Automatically capture distance learning attendance in our Learning Management System.",
            logo: "/images/about/about15.svg",
        },
        {
            title: "Continuous Support",
            subtitle: "Support continuous professional development and compliance with effective learning.",
            logo: "/images/about/about16.svg",
        },
    ],
};
const getters = {
    aboutBusiness: state => {
        return state.aboutBusiness;
    },
};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
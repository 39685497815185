const state = {
    userTypeContent: {
        learner: {
            type: 'learner',
            background: "var(--tertiary)",
            banner: "var(--primary)",
            backgroundlogo: '/images/customer/learner.svg',
            logo: "/images/logo/pp-logo-individual-white.svg",
            image: "/images/customer/learner-2.jpg",
            to: '/learners/#browse',
            title: "Discover our wide range of courses, available across a variety of subject areas",
            subtitle: "Join thousands of others who have used our digital learning system to keep up in a fast-paced jobs market. Whether you're looking to take the next step in your career or train in a new field, we have an extensive range of courses that can help you achieve this.",
        },
        employer: {
            type: 'employer',
            background: "var(--tertiary-plum)",
            banner: "var(--tertiary-green)",
            backgroundlogo: '/images/customer/employer-2.svg',
            logo: "/images/logo/pp-logo-business-white.svg",
            image: "/images/customer/employer-2.jpg",
            to: "/employers/#browse",
            learnmore: '/learn-more',
            title: "We provide market-leading training solutions across a wide variety of sectors",
            subtitle: "Whether you are looking to enhance your employee's knowledge, wanting to develop your team's talent, are searching for an effective delivery system or are unsure where to start with your training needs, we can help.",
            body1: "LearningPlus is successfully used by many organisations to reduce costs, increase profits and consistently achieve complete compliance audits. We help organisations meet regulatory requirements for the delivery, certification, and management of training. The LearningPlus management system enables you to manage all your training records, site procedures, E-learning and external training records in one place. Giving your workforce the right knowledge and skills to ensure that non-compliance is a non-issue.",
            body2: "We pride ourselves not only on our learner journey, but on our first-class account management. This is highlighted through our continued success working with over 500+ employer sites across a wide variety of sectors. With a vast range of sector courses, we have something for everyone. ",
        },
        educationSector: {
            type: 'educationSector',
            background: "var(--darkblue)",
            banner: "var(--lime)",
            backgroundlogo: '/images/customer/education-2.svg',
            logo: "/images/logo/pp-logo-education-white.svg",
            image: "/images/customer/education-2.jpg",
            to: "/education-sector/#browse",
            learnmore: '/learn-more',
            provider: 'provider',
            title: "Change the way people learn",
            subtitle: "With our combination of market-leading learning resources, exceptional customer service and innovative LMS solutions, we are confident we can assist with your delivery needs.",
            body1: "LearningPlus was created with a clear vision and purpose - to provide high-quality support services to the FE sector, organisations and individuals, developing a flexible way to study in order to improve job prospects and enhance career opportunities. From developing high-quality learning resources to dedicated Account managers and Learner Advisors guiding learners through their journey - it's all about the learner!",
            body2: "As a direct contract holder with The Education and Skills Funding Agency we understand the requirements of compliant project delivery. Our partnerships team is the hub of our wider organisation with dedicated departments which link together building our reliable and efficient services, from quality and product development to our learner support team.",
        },
    },

};
const getters = {
    userTypeContentLearner: state => {
        return state.userTypeContent.learner;
    },
    userTypeContentEmployer: state => {
        return state.userTypeContent.employer;
    },
    userTypeContentEducationSector: state => {
        return state.userTypeContent.educationSector;
    },
};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};

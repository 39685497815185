<template>
  <v-btn
      title="Email LearningPlus Support"
      aria-labelledby="Email LearningPlus Support"
      color="primary"
      rounded
      height="50"
      width="246"
      class="pp-scale-1"
      :href="supportEmail.href"
      @click="matomoEvent('Contact Support', 'Contact Support Click', 'Contact Support Email');"
  >
    <v-icon class="mx-2">
      mdi-card-account-mail
    </v-icon>
    Contact Us
  </v-btn>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ContactUsButton",
  computed: {
    ...mapGetters(['supportEmail']),
  },
};
</script>

const state = {
    supportEmail: {
        href: 'mailto:support@learningplus.co.uk',
        title: 'Email LearningPlus Support',
        linkText: 'Select this to email support@learningplus.co.uk',
        email: 'support@learningplus.co.uk',
    },
};
const getters = {
    supportEmail: state => {
        return state.supportEmail;
    },
};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
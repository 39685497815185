const state = {
    categoryCourses: [],
    categoryCoursesLoading: false,
    categoryCoursesPagination: null,
};
const getters = {
    categoryCourses: state => {
        return state.categoryCourses;
    },
    categoryCoursesLoading: state => {
        return state.categoryCoursesLoading;
    },
    categoryCoursesPagination: state => {
        return state.categoryCoursesPagination;
    },
};
const actions = {
    getCategoryCoursesByCategorySlug(context, {category = String, inputOptions = {}}) {

        context.commit('setCategoryCoursesLoading', true);

        // @todo Task #3650 Add something that calls this with another page
        context.dispatch('retrieveCoursesFromApi', {
            searchCriteria: {
                "categories.slug": category,
            },
            inputOptions,
        }).then(response => {
            context.commit('setCategoryCoursesLoading', false);
            context.commit('setCategoryCoursesByCategorySlug', response);
        });
    },
};
const mutations = {
    clearCategoryCourses(state) {
        state.categoryCourses = [];
    },
    setCategoryCoursesByCategorySlug(state, courseResults) {
        state.categoryCourses = courseResults.docs;
        state.categoryCoursesPagination = {
            page: courseResults.page,
            totalPages: courseResults.totalPages,
        };
    },
    setCategoryCoursesLoading(state, loading) {
        state.categoryCoursesLoading = loading;
        state.categoryCoursesPagination = null;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
<template>
  <div>

    <v-row>
      <v-col cols="1">
        <v-checkbox
            v-model="enabled"
            hide-details
            class="shrink mr-2 mt-0"
        ></v-checkbox>
      </v-col>

      <v-col class="text-subtitle-2 pr-0 ml-2 ml-sm-0">
        <v-sheet color="transparent" class="font-weight-regular">
          I agree to the
          <a target="_blank" :href="terms" rel="noopener noreferrer">
            LearningPlus Terms of Service
          </a>
           and I have read the
          <a target="_blank" :href="policy" rel="noopener noreferrer">
            Privacy Policy.
          </a>
        </v-sheet>
      </v-col>
    </v-row>

    <v-btn
        @click="signup"
        rounded
        color="primary"
        class="mt-3 pp-center"
        :disabled="!enabled">
      Continue
      <span class="hidden-xs-only">
         to sign up
      </span>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'SignUpRedirect',
  props: {
      redirectUri: {
        type: String,
        default: window.location.origin + '/profile',
      },
  },
  data: () => ({
    accountType: 'individual',
    enabled: false,
    policy: '/policies/privacy-policy',
    terms: '/policies/terms-and-conditions',
  }),
  methods: {
    ...mapActions(['hideBasket']),
    signup() {
      this.matomoEvent(
          'Sign Up',
          'Sign Up Click',
          'Sign Up for a LearningPlus Profile',
          this.accountType,
      );
      this.$auth.loginWithRedirect({
        screen_hint: 'signup',
        account_type: this.accountType,
        redirect_uri: this.redirectUri,
      });
    },
  },
};
</script>

<style>
a:hover {
  font-weight: bold;
}

.pp-center {
  width: 220px;
}
</style>

<template>
  <div class="">
    <v-sheet height="100">
      <div class="pp-hooper-1">
        <hooper :settings="hooperLogoSettings"
                class="pp-hooper-2"
        >
          <slide v-for="(logo, i) in trustedLogoImages"
                 :key="i">
            <picture>
              <img
                  :src="logo.url"
                  :alt="logo.alt"
                  height="70"
                  width="100px"
                  class="ma-2 pp-grayscale contain"
              />
            </picture>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </v-sheet>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import { Hooper, Slide, Navigation as HooperNavigation} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: "LogosDesktop",
  computed: {
    ...mapGetters(['trustedLogoImages']),
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data () {
    return {
      hooperLogoSettings: {
        itemsToShow: 8,
        infiniteScroll: true,
        mouseDrag: true,
        touchDrag: true,
        wheelControl: false,
        keysControl: false,
        shortDrag: false,
        transition: 500,
        hoverPause: false,
        autoPlay: true,
        playSpeed: 1500,
        centerMode: true,
      },
    };
  },
};
</script>

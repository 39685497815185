import { render, staticRenderFns } from "./SocialLinks.vue?vue&type=template&id=349e55dd&scoped=true&"
import script from "./SocialLinks.vue?vue&type=script&lang=js&"
export * from "./SocialLinks.vue?vue&type=script&lang=js&"
import style0 from "./SocialLinks.vue?vue&type=style&index=0&id=349e55dd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349e55dd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VListItem,VListItemContent})

import axios from "axios";
import axiosMock from "axios-mock-adapter";

const mock = new axiosMock(axios);

mock.onGet(`${process.env.VUE_APP_SERVICE_URI_COURSE}/api/courses`, { params: {
        tags: 'learning_plus',
        popular: true,
        page: 1,
    }}).reply(200, {
    docs: [
        {
            uuid: 1,
            name: "Dummy Course 1",
            slug: "dummy-1",
            description: "Dummy Course 1 description",
            priceInUnits: 100,
            popular: true,
        },
        {
            uuid: 2,
            name: "Dummy Course 2",
            slug: "dummy-2",
            description: "Dummy Course 2 description",
            priceInUnits: 100,
            popular: true,
        },
    ],
});
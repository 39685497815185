<template>
  <v-col class="pa-0 ma-0 ml-md-15">
    <Contacts></Contacts>
  </v-col>
</template>

<script>
import Contacts from "./Contacts";
export default {
  name: "Column3",
  components: {Contacts},
};
</script>

<style scoped>

</style>

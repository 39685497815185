<template>
  <v-container class="pa-0">
    <img class="mb-10"
            height="86"
            width="250"
            src="/images/logo/pp-logo-white.svg"
           alt="LearningPlus Logo"
    >

    <div class="white--text">
      <EditableText handle="footer-compliance" v-model="contentItem" />
    </div>

    <v-btn
        class="my-2 flat text-sm-button text-subtitle-1 pp-scale-1"
        outlined
        color="accent"
        rounded
        target="_blank"
        rel="noopener noreferrer"
        href="https://peopleplus.co.uk/"
        title="Learn more about PeoplePlus"
        aria-labelledby="Learn more about PeoplePlus"
    >
      Learn more about PeoplePlus
      <v-icon class="ml-2 hidden-xs-only">
        mdi-arrow-right
      </v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import EditableText from "../EditableText";
import store from "../../store/store";
export default {
  name: "Compliance",
  components: {EditableText},
  computed: {
    contentItem: {
      get() {
        return store.getters.content('footer-compliance');
      },
    },
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-btn @click="hideBasket"
             dark
             icon
             class="pa-8"
             title="Hide Basket"
             aria-labelledby="Hide Basket"
      >
        <v-icon color="primary">
          mdi-close
        </v-icon>
      </v-btn>

      <v-spacer />
      <div class="hidden-xs-only ma-3 mb-0">
        <LearningPlusLogo :width="150" :height="50"/>
      </div>
    </v-row>
  </v-container>
</template>

<script>

import {mapActions} from "vuex";
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";

export default {
  name: "Header",
  components: {LearningPlusLogo},
  methods: {
    ...mapActions(['hideBasket']),
  },
};
</script>

export const hasRoleMixin = {
    methods: {
        hasRole(user, rolesToFind) {
            if (!user || !user['http://p3.peopleplus.truenorthit.co.uk/roles']) {
                return false;
            }

            return user['http://p3.peopleplus.truenorthit.co.uk/roles'].some( role => rolesToFind.includes(role) );
        },
    },
};

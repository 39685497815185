import axios from "axios";
import { getInstance } from "@/auth";
import store from "../store";

axios.interceptors.request.use(async config => {

        if(store.getters.auth0Authenticated) {
            config.headers.Authorization = `Bearer ${store.getters.auth0Token}`;
        }

        return config;

    }, error => {
        return Promise.reject(error);
    },
);

const state = {
    auth0Loading: null,
    auth0Token: null,
    auth0Authenticated: null,
};

const getters = {
    auth0Loading: state => {
        return state.auth0Loading;
    },
    auth0Token: state => {
        return state.auth0Token;
    },
    auth0Authenticated: state => {
        return state.auth0Authenticated;
    },
};

const actions = {
    async getAuth(context) {

        return new Promise((resolve, reject) => {

            const auth = getInstance();
            context.commit('setAuth0Loading', true);

            auth.$watch("loading", loading => {
                if (loading === false && auth.isAuthenticated) {
                    auth.getTokenSilently().then(authToken => {
                        context.commit('setAuth0Loading', false);
                        context.commit('setAuth0Authenticated', true);
                        context.commit('setAuth0Token', authToken);
                        resolve();
                    }).catch(error => {
                        context.commit('setAuth0Loading', false);
                        context.commit('setAuth0Authenticated', false);
                        reject(error);
                    });
                }

                if (loading === false && !auth.isAuthenticated) {
                    context.commit('setAuth0Loading', false);
                    context.commit('setAuth0Authenticated', false);
                    resolve();
                }
            });

        });

    },
};

const mutations = {
    setAuth0Loading(state, loading){
        state.auth0Loading = loading;
    },
    setAuth0Authenticated(state, authenticated){
        state.auth0Authenticated = authenticated;
    },
    setAuth0Token(state, authToken){
        state.auth0Token = authToken;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
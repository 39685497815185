const state = {
    userSteps: {
        individual: [
            {
                text: 'Browse our variety of in-demand skills with over 400 courses to choose from',
                icon: '/images/customer/step01.svg',
                alt: 'Step 1 icon',
            },
            {
                text: 'Add the courses to your basket and set up a profile so you can check-out',
                icon: '/images/customer/step02.svg',
                alt: 'Step 2 icon',
            },
            {
                text: 'Head to our Portal to start your learning',
                icon: '/images/customer/step03.svg',
                alt: 'Step 3 icon',
            },
        ],
        business: [
            {
                text: 'Browse our variety of in-demand skills with over 400 courses to choose from',
                icon: '/images/customer/step01.svg',
                alt: 'Step 1 icon',
            },
            {
                text: 'Ready to upskill your team? Simply enter a few details on the form',
                icon: '/images/customer/step02-business.svg',
                alt: 'Step 2 icon',
            },
            {
                text: 'One of our account managers will be in touch',
                icon: '/images/customer/step03-business.svg',
                alt: 'Step 3 icon',
            },
        ],
    },

};
const getters = {
    userStepsIndividual: state => {
        return state.userSteps.individual;
    },
    userStepsBusiness: state => {
        return state.userSteps.business;
    },
};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
const state = {
    companyStructure: [
        {
            name: "Laura Savage",
            title: "Director of Partner Services",
            description: "Laura has worked at PeoplePlus for over 8 years and with over 20 years’ experience in Education, Business Innovation, Skills and Recruitment sector is keen to ensure LearningPlus is the go to solution for customers because of its Learning Management System, MI and Data reporting capabilities, off the shelf courses, own build courses, e-learning conversion capabilities, content procurement, Netflix style aggregation capabilities; or a mixture of all!",
            image: "/images/team/laura-savage.jpg",
        },
        {
            name: "Jenifer Graley",
            title: "Administration Coordinator",
            description: "Jen comes form an adult education background and has also been a LearningPlus customer! She has taught a range LearningPlus courses both face to face and through remote teaching so brings a good knowledge of our customers needs and expectations. Jen is keen to ensure that all of our customers have the best possible experience from start to finish.",
            image: "/images/team/jenifer-graley.jpg",
        },
        {
            name: "Lydia Baker",
            title: "Business Development Manager",
            description: "Lydia comes from a Skills background and was a LearningPlus customer! She has led one of the largest regional Skills contracts and therefore brings her combined knowledge of operational Adult Education and user experience to this role where she leads the development of our Training and Employability provider services. Lydia also plays a big part in improving our operational activity as we come into our 2022 pivotal development year.",
            image: "/images/team/lydia-baker.jpg",
        },
        {
            name: "Gabriela Watroba",
            title: "Account Manager",
            description: "Gabriela has been with LearningPlus for 6 years and will be a familiar face to most of you! Gabriela is a fountain of knowledge within things LearningPlus and leads on the Operational support of our Training and Employability provider clients. Her customer service is second to none and she’s always keen to help clients get the most out of the platform.",
            image: "/images/team/gabriela-watroba.jpg",
        },
    ],
};
const getters = {
    companyStructure: state => {
        return state.companyStructure;
    },
};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};

import axios from "./common";

const state = {
    currentPolicy: null,
    policyLoading: true,
    policyNames: {
        'acceptable-use-policy': {
            'filename': 'acceptable-use-policy.md',
            'preText': true,
        },
        'complaints-and-compliments': {
            'filename': 'complaints-and-compliments.md',
            'preText': true,
        },
        'cookies-policy': {
            'filename': 'cookies-policy.md',
            'preText': true,
        },
        'modern-slavery': {
            'filename': 'modern-slavery.md',
            'preText': false,
        },
        'privacy-policy': {
            'filename': 'privacy-policy.md',
            'preText': false,
        },
        'terms-and-conditions': {
            'filename': 'terms-and-conditions.md',
            'preText': true,
        },
    },
};


const getters = {
    currentPolicy: state => {
        return state.currentPolicy;
    },
    policyLoading: state => {
        return state.policyLoading;
    },
    hasPreText: state => (policy) => {
        return state.policyNames[policy].preText;
    },
};

const actions = {
    async getPolicy(context, policyName) {
        context.commit('setPolicyLoading', true);
        const policyFile = context.state.policyNames[policyName].filename;
        try {
            const response = await axios.get(`${process.env.VUE_APP_POLICY_BASE_URL}/$web/policies/${policyFile}`);
            context.commit('setPolicy', response.data);
            context.commit('setPolicyLoading', false);
        } catch(e) {
            context.commit('setPolicyLoading', false);
            context.commit('setPolicy', '');
            throw 'Policy not found';
        }
    },
};

const mutations = {
    setPolicy(state, policy) {
        state.currentPolicy = policy;
    },
    setPolicyLoading(state, loading) {
        state.policyLoading = loading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

const state = {
    marketingNumbers:{
        coursesDelivered: 300,
        coursesDeliveredMedium: 300000,
        coursesAvailable: 400,
        employerSites: 500,
        yearsInBusiness: new Date().getUTCFullYear() - 2012,
    },
};

const getters = {
    marketingNumbers: state => {
        return state.marketingNumbers;
    },
};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};

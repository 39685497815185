<template>
  <v-container class="text-center">

    <div class="text-uppercase font-weight-bold pp-title mb-8">
      Shopping Cart
    </div>

    <picture>
      <img
          src="/images/logo/basket-icon.svg"
          alt="Empty basket"
          height="300"
          class="contain"
      />
    </picture>


    <div class="text-h6 text-sm-h4 font-weight-bold secondary--text mt-10">
      Your cart is currently empty!
    </div>

    <div class="mt-5 grey--text darken-1">
      <p >
        Before proceed to checkout you must add some courses to your shopping cart.
      </p>

      <p>
        You will find a lot of interesting courses on our page.
      </p>
    </div>

    <v-btn
        width="246"
        class="rounded-xl mt-5"
        @click="hideBasket()"
    >
      <BrowseCourseButton />
    </v-btn>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import BrowseCourseButton from "@/components/buttons/BrowseCourseButton";

export default {
  name: "BasketEmpty",
  components: {BrowseCourseButton},
  methods: {
    ...mapActions(['hideBasket']),
  },
};
</script>

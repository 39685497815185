import axios from "./common";

const state = {
    trustedLogoImages: [],
    trustedLogosLoading: null,
};
const getters = {
    trustedLogoImages: state => {
        return state.trustedLogoImages;
    },
    trustedLogosLoading: state => {
        return state.trustedLogosLoading;
    },
};
const actions = {

    getTrustedLogos(context, page) {

        const options = {
            page: page,
            tags: "company_logos",
        };

        context.commit('setTrustedLogosLoading', true);
        context.commit('clearTrustedLogos');

        axios.get(`${process.env.VUE_APP_SERVICE_URI_FILE}/api/images`, {params: options})
            .then(response => {

                context.commit('setTrustedLogosLoading', false);
                context.commit('setTrustedLogos', response.data.docs);

                if (response.data && response.data.hasNextPage) {
                    actions.getTrustedLogos(context, page + 1);
                }
            });
    },
};

const mutations = {
    setTrustedLogos(state, trustedLogos) {
        state.trustedLogoImages.push(...trustedLogos);
    },
    clearTrustedLogos(state){
        state.trustedLogoImages = [];
    },
    setTrustedLogosLoading(state, loading) {
        state.trustedLogosLoading = loading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

<template>
  <div>
    <div
        @mouseover="hover = true"
    >
      <v-row class="mb-2">
        <v-btn v-if="cmsEnabled && hover"
               @click="toggleEditor"
               rounded
               class="ml-3 text-body-2"
               small
        >
          <v-icon class="mr-2" small>
            mdi-pencil
          </v-icon>
          Edit
        </v-btn>

        <v-btn
            v-if="cmsEnabled && hover && value != initial"
            @click="revertEditor"
            fab
            color="red"
            x-small
            class="mx-2"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>

        <v-btn
            v-if="cmsEnabled && hover && value != initial"
            @click="save"
            fab
            color="green"
            x-small
            class="mr-3"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </v-row>

      <div v-html="value"/>

    </div>

    <div v-if="editing">
      <v-card
          v-text="handle"
          class="pa-2 mb-3"
          color="grey lighten-1"
      >
      </v-card>

      <bubble-menu
          class="bubble-menu"
          :tippy-options="{ duration: 100 }"
          :editor="editor"
          v-if="editor"
      >
        <button
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
        >
          Bold |
        </button>

        <button
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
        >
          Italic |
        </button>

        <button
            @click="editor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': editor.isActive('strike') }"
        >
          Strike
        </button>
      </bubble-menu>

      <floating-menu
          class="floating-menu"
          :tippy-options="{ duration: 100 }"
          :editor="editor"
          v-if="editor"
      >
        <button
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >
          H1
        </button>

        <button
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        >
          H2
        </button>

        <button
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          Bullet List
        </button>
      </floating-menu>

      <editor-content :editor="editor"
                      class="white secondary--text pa-2 rounded elevation-10 mb-3"
      />
    </div>

  </div>
</template>

<script>

import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import {mapGetters, mapState} from "vuex";
import store from "../store/store";

export default {
  name: "EditableText",
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    handle:{
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      hover: false,
      editing: false,
      initial: '',
    };
  },
  computed: {
    ...mapState(['cmsEnabled']),
    ...mapGetters(['cmsEnabled']),
  },
  methods: {
    toggleEditor(){
      this.editing = !this.editing;

      if(this.editing){
        this.newEditor();
      }
    },
    revertEditor(){
      this.value = this.initial;
      this.editing = !this.editing;
      this.newEditor();
    },
    save(){
      this.editing = !this.editing;
      store.dispatch('saveContentItem', {handle: this.handle, content: this.editor.getHTML()});
    },
    newEditor(){
      this.editor = new Editor({
        extensions: [
          StarterKit,
        ],
        content: this.value,
        onUpdate: () => {
          // HTML
          //this.$emit('input', this.editor.getHTML())
          store.dispatch('setContentItem', {handle: this.handle, content: this.editor.getHTML()});
          this.value = this.editor.getHTML();
          // JSON
          // this.$emit('input', this.editor.getJSON())
        },
      });
    },
  },
  mounted() {
    this.initial = this.value;
    this.newEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss" scoped>
.bubble-menu {
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    color: #FFF;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>
import axios from './common';

if (process.env.VUE_APP_FAKE_SERVICES === 'true') {
    require('./fake/reviews.js');
}

const state = {
    reviews: [],
    reviewsLoading: true,
};

const getters = {
    reviews: state => {
        return state.reviews;
    },
    reviewsLoading: state => {
        return state.reviewsLoading;
    },
};

const actions = {

    getReviews(context, page) {

        const options = {
            page: page,
            tags: "learning_plus",
        };

        context.commit('setReviewsLoading', true);
        context.commit('clearReviews');

        axios.get(`${process.env.VUE_APP_SERVICE_URI_REVIEW}/api/reviews`, {params: options})
            .then(response => {

                context.commit('setReviewsLoading', false);
                context.commit('setReviews', response.data.docs);

                if (response.data && response.data.hasNextPage) {
                    actions.getReviews(context, page + 1);
                }
            });
    },
};

const mutations = {
    setReviews(state, reviews) {
        state.reviews.push(...reviews);
    },
    clearReviews(state){
        state.reviews = [];
    },
    setReviewsLoading(state, loading) {
        state.reviewsLoading = loading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

<template>
  <div>
    <v-container fluid class="pa-0 ma-0 pp-header pp-shadow-1 hidden-lg-and-up">
      <div class="nav ml-4">
        <v-menu v-model="menu" transition="scale-transition" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                   color="transparent"
                   depressed
                   v-bind="attrs"
                   v-on="on"
                   fab
                   title="Open Menu"
                   aria-labelledby="Open Menu"
            >
              <v-icon color="primary pa-0" large>
                mdi-menu
              </v-icon>
            </v-btn>
          </template>

          <v-list width="15em" class="px-2 red-gradient-container" dark>
            <v-list-item
                v-if="$auth.isAuthenticated"
                link
                target="_blank"
                :href="portal"
                @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'LearningPlus Portal');"
                class="menu-item primary rounded"
                title="Go to LearningPlus Portal"
                aria-labelledby="Go to LearningPlus Portal"
            >
              <v-list-item-title>
                <v-icon class="mr-2 mb-1">
                  mdi-cast-education
                </v-icon>
                <span v-if="hasRole($auth.user, ['LearningPlusBusiness'])">LearningPlus Portal</span>

                <span v-else>My Learning </span>
              </v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item
                v-if="hasRole($auth.user, ['LearningPlusBusiness'])"
                link
                target="_blank"
                :href="pps"
                @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'PPS Portal');"
                class="menu-item primary rounded"
            >
              <v-list-item-title>
                <v-icon class="mr-2 mb-1">
                  mdi-laptop
                </v-icon>
                Visit PPS
              </v-list-item-title>
            </v-list-item>

            <div v-else>
              <div class="pa-0 ma-0" v-if="$auth.isAuthenticated">
                <v-list-item link class="menu-item"
                             to="/profile">
                  <v-list-item-title>
                    <v-icon class="mr-2 mb-1">
                      mdi-account-cog
                    </v-icon>
                    Profile
                  </v-list-item-title>
                </v-list-item>
                <v-divider />
              </div>

              <div v-for="(menu,index) in menus" :key="index" >
                <v-list-item link :to="menu.path" class="menu-item">
                  <v-list-item-title>
                    <v-icon class="mr-2 mb-1">
                      mdi-{{menu.icon}}
                    </v-icon>
                    {{menu.title}}
                  </v-list-item-title>
                </v-list-item>
                <v-divider />
              </div>

              <div class="pa-0 ma-0" v-if="!$auth.isAuthenticated" @click="login">
                <v-list-item link
                             class="menu-item"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2 mb-1">
                      mdi-login-variant
                    </v-icon>
                    Log In
                  </v-list-item-title>
                </v-list-item>

                <v-divider />
              </div>

              <v-row v-if="!$auth.isAuthenticated">
                <v-divider />
                <v-dialog
                    v-model="dialog"
                    fullscreen hide-overlay
                    transition="dialog-bottom-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-card
                        color="transparent"
                        class="pa-5 font-weight-bold"
                        v-bind="attrs" v-on="on"
                        width="100%"
                    >
                      <v-icon class="mx-2">
                        mdi-account-plus-outline
                      </v-icon>
                      Sign Up
                    </v-card>
                  </template>
                  <v-card color="accent" class="signup-bg">
                    <v-row class="pt-10">
                      <LearningPlusLogo :height="20" />
                      <div class="pp-hr pp-center"></div>
                    </v-row>
                    <SignUpRedirect class="pa-5" />
                    <v-row justify="center" class="transparent">
                      <v-btn
                          @click="dialog = false"
                          class="elevation-0"
                          color="accent" rounded
                      >
                        <v-subheader>Cancel</v-subheader>
                      </v-btn>
                    </v-row>
                  </v-card>
                </v-dialog>
              </v-row>
            </div>
            <div class="pa-0 ma-0" v-if="$auth.isAuthenticated" @click="logout">
              <v-divider></v-divider>

              <v-list-item link
                           class="menu-item"
              >
                <v-list-item-title>
                  <v-icon class="mr-2 mb-1">
                    mdi-logout-variant
                  </v-icon>
                  Sign Out
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>

        <v-col cols="6">
          <router-link
              @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'Home Page');"
              :to="{ name: 'Home' }">
            <LearningPlusLogo :height="25" />
          </router-link>
        </v-col>

        <div class="bar">
          <Basket></Basket>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Basket from "@/components/Basket";
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";
import SignUpRedirect from "@/components/common/SignUpRedirect";
import { hasRoleMixin } from "@/mixin/hasRoleMixin";

export default {
  name: "MediumHeader",
  mixins: [hasRoleMixin],
  components: {SignUpRedirect, LearningPlusLogo, Basket},
  data: () => ({
    menu: false,
    dialog: false,
    portal: process.env.VUE_APP_LEARNER_PORTAL,
    pps: process.env.VUE_APP_PPS,
    menus: [
      {
        title: 'Learners',
        path: '/learners',
        icon: 'book-variant',
      },
      {
        title: 'Employers',
        path: '/employers',
        icon: 'account-box-multiple-outline',
      },
      {
        title: 'Education Sector',
        path: '/education-sector',
        icon: 'book-education-outline',
      },
      {
        title: 'About Us',
        path: '/about',
        icon: 'plus',
      },
      {
        title: 'FAQs',
        path: '/about',
        icon: 'message-question',
      },
      {
        title: 'Contact Us',
        path: '/#contact',
        icon: 'email-outline',
      },
    ],
  }),
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    menuItem(trackString) {
      this.matomoEvent('Page Navigation', 'Navigate to page Click', trackString);
      this.menu = false;
    },
  },
};

</script>

<style scoped>

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.menu-item  {
  color: red !important;
  font-weight: bolder;
  margin: 5px 0;
}

.bar {
  margin-top: 5px;
  text-align: left;
  display: flex;
  height: 50px;
  align-items: center;
}

.v-btn::before {
  background-color: transparent;
}

.v-btn:not(.v-btn--round).v-size--large {
  height: 44px;
  min-width: 60px;
  margin-left: 5px;
  padding: 0 19px;
}

.pp-header {
  position: fixed;
  background-color: #ECF0F3;
  z-index: 100;
  top: 0;
  left: 0;
}

.signup-bg {
  background: #D0D0CD url("/images/shades/signup-bg.svg") bottom right no-repeat;
  background-size: contain;
  height: 100%;
}
</style>

<template>
  <v-footer class="footer">
    <v-container width="100%" fluid>
      <v-row>
        <v-col class="pa-0 pa-md-5">
          <Column1></Column1>
        </v-col>
        <v-col>
          <Column3></Column3>
        </v-col>
        <v-col>
          <Column2></Column2>
        </v-col>
      </v-row>
      <v-row>
        <Copyright></Copyright>
      </v-row>
      
      <Cookie/>
    </v-container>
  </v-footer>
</template>

<script>
import Column1 from "@/components/footer/Column1";
import Column2 from "@/components/footer/Column2";
import Column3 from "@/components/footer/Column3";
import Copyright from "@/components/footer/Copyright";
import Cookie from "@/components/footer/Cookie";

export default {
  name: "Footer",
  components: { Copyright, Column3, Column2, Column1, Cookie },
};
</script>

<style scoped>
</style>

const state = {
    aboutLearningPlus: {
      individual: [
          {
              text: "Remain flexible with your study schedule",
              logo: "/images/about/about03.svg",
          },
          {
              text: "Attend online exams from the comfort of your home",
              logo: "/images/about/about04.svg",
          },
          {
              text: "Highly focused content based on a single topic",
              logo: "/images/about/about06.svg",
          },
          {
              text: "Display the qualification on your CV",
              logo: "/images/about/about07.svg",
          },
          {
              text: "Easy, seamless and intuitive platform",
              logo: "/images/about/about05.svg",
          },
          {
              text: "Benefit from the limitless educational possibilities",
              logo: "/images/about/about08.svg",
          },
      ],

        business: [
            {
                text: "Simple LMS software to manage training records and site procedures",
                logo: "/images/about/about09.svg",
            },
            {
                text: "Customise training plans and staff surveys",
                logo: "/images/about/about10.svg",
            },
            {
                text: "Assess literacy and numeracy standards against job roles",
                logo: "/images/about/about11.svg",
            },
            {
                text: "Share Reporting Suite analytics, insights and dashboards",
                logo: "/images/about/about12.svg",
            },
            {
                text: "Future-proof your workforce by upskilling your in-house talent",
                logo: "/images/about/about13.svg",
            },
            {
                text: "Keep it simple within one secure login to maintain the security and confidentiality",
                logo: "/images/about/about14.svg",
            },
            {
                text: "Support remote workforce by capturing distance learning attendance",
                logo: "/images/about/about03.svg",
            },
            {
                text: "Support continuous professional development and compliance with effective learning",
                logo: "/images/about/about16.svg",
            },
        ],
    },
};
const getters = {
    aboutLearningPlusIndividual: state => {
        return state.aboutLearningPlus.individual;
    },
    aboutLearningPlusBusiness: state => {
        return state.aboutLearningPlus.business;
    },
};
const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations,
};
<template>
  <v-container>

    <Loading :loading="reviewsLoading"
             title="Loading Reviews"
    />

    <div
        v-if="!reviewsLoading && reviews && reviews.length > 0"
    >
      <v-row no-gutters>
        <div class="pp-title mt-15 ml-xl-5">
          What Our Clients Say
        </div>

        <hooper :settings="hooperReviewConfig"
                class="layer-2 review-container"
        >
          <slide v-for="(review, i) in reviews" :key="i"
                 class="mt-5 pl-md-5"
          >

            <v-card width="18rem"
                    min-height="415px"
                    class="rounded-xl mt-5"
            >
              <v-list-item>
                <v-list-item-avatar size="60">

                  <picture v-if="review.image">
                    <img :src="review.image.url"
                         :alt="review.image.alt"
                         height="80"
                         class="cover"
                    />
                  </picture>

                  <picture v-else>
                    <img src="/images/icons/review-default.jpg"
                         alt="A LearningPlus user"
                         height="60"
                         width="60"
                         class="cover"
                    />
                  </picture>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="primary--text text-h6 mb-0">
                    {{review.author}}
                  </div>

                  <div class="text-body-2 secondary--text">
                    {{review.title}}
                  </div>
                </v-list-item-content>
              </v-list-item>

              <div class="mt-3">
                <div class="pp-quote"></div>

                <v-card-subtitle
                    class="pt-0 pb-8"
                    v-html="review.review"
                >
                </v-card-subtitle>

                <v-img src="/images/icons/pp-quote.svg"
                       alt="quote icon"
                       class="right contain"
                       width="20"
                />
              </div>
            </v-card>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </v-row>
    </div>


  </v-container>
</template>

<script>
import {mapGetters, mapState, mapActions} from "vuex";
import Loading from "@/components/common/Loading";
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
} from 'hooper';

export default {
  name: "PersonalReviews",
  components: {Loading, Hooper, Slide, HooperNavigation},
  computed: {
    ...mapState(['reviews', 'reviewsLoading']),
    ...mapGetters(['reviews', 'reviewsLoading']),

    hooperReviewConfig() {
      let config = {
        mouseDrag: true,
        touchDrag: true,
        wheelControl: false,
        infiniteScroll: true,
        centerMode: true,
      };

      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          config.itemsToShow = 1;
          break;
        case 'sm':
          config.itemsToShow = 2;
          break;
        case 'md':
          config.itemsToShow = 2.5;
          break;
        case 'lg':
          config.itemsToShow = 3;
          break;
        case 'xl':
          config.itemsToShow = 5;
          break;
      }

      return config;
    },
  },
  methods: {
    ...mapActions(['getReviews']),
  },
  beforeMount() {
    this.getReviews(1);
  },
};
</script>

<template>
  <div>
    <v-row class="mt-1 pl-3">
      <v-btn @click="update" fab small depressed>
        <v-btn @click="remove"
               fab
               small
               depressed
               title="Remove Course"
               aria-label="Remove Course"
        >
          <v-icon color="primary">
            mdi-minus
          </v-icon>
        </v-btn>
      </v-btn>

      <v-sheet class="pa-2 text-subtitle-1" fab small plain>
        {{ amountToReplaceBasket }}
      </v-sheet>
      <v-btn @click="update" fab small depressed>
        <v-btn @click="add"
               fab
               small
               depressed
               title="Remove Course"
               aria-label="Remove Course"
        >
          <v-icon color="primary">
            mdi-plus
          </v-icon>
        </v-btn>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";

export default {
  name: "BuyMultiple",
  mixins: [matomoEcommerce],
  data: () => ({
    dialog: false,
    amountToReplaceBasket: 0,
  }),
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['basketValue']),
    dialogWidth() {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 320;
      }
      return 450;
    },
  },
  mounted() {
    this.amountToReplaceBasket = this.item.quantity;
  },
  methods: {
    ...mapActions(['replaceBasketItem']),
    signUpDecline() {
      this.dialog = false;
      this.matomoEvent('Sign Up', 'Sign Up Decline', 'Decline Sign up for business price');
    },
    add() {
      ++this.amountToReplaceBasket;
    },
    remove() {
      this.amountToReplaceBasket--;
      if (this.amountToReplaceBasket < 1) {
        this.amountToReplaceBasket = 1;
      }
    },
    update() {
      this.item.amountToReplaceBasket = this.amountToReplaceBasket;
      this.replaceBasketItem(this.item);
      this.matomoAddEcommerceItem(this.item._id, this.item.name, "", this.item.priceInUnits, this.item.quantity);
      this.matomoTrackEcommerceCartUpdate(this.basketValue);
    },
  },
};
</script>

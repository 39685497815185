<template>
  <v-container fluid class="hidden-md-and-down pp-header pp-shadow-1 blue-bg">

    <div v-if="hasRole($auth.user, ['LearningPlusBusiness'])">
      <v-row class="bs-page-logo" >
        <v-col cols="3">
          <LearningPlusLogo :height="20"/>
        </v-col>
      </v-row>

      <div class="justify-end d-flex">

        <v-btn rounded
               class="font-weight-light transparent text-subtitle-2 mr-1"
               depressed
               :href="portal"
               target="_blank"
        >
          LearningPlus Portal
          <v-icon small class="pl-2">
            mdi-cast-education
          </v-icon>
        </v-btn>

        <v-btn color="primary" dark rounded :href="pps" target="_blank">
          PeoplePlus System (PPS)
        </v-btn>

        <v-btn rounded
               class="font-weight-light transparent text-subtitle-2 ml-1"
               depressed
               @click="logout"
        >
          Sign Out
          <v-icon small class="pl-2">
            mdi-logout
          </v-icon>
        </v-btn>
      </div>
    </div>

    <v-card v-else class="d-flex justify-start transparent" flat tile>
      <div class="mr-5">
        <router-link
            title="Go to Homepage"
            aria-labelledby="Homepage"
            :to="{ name: 'Home' }"
            @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'Home Page');"
        >
          <LearningPlusLogo :width="150"/>
        </router-link>
      </div>

      <router-link
          title="Go to Homepage"
          aria-labelledby="Homepage"
          :to="{ name: 'Home' }"
          @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'Home Page');"
      >
        <v-icon class="home-nav" color="lightslategrey">
          mdi-home-search
        </v-icon>
      </router-link>

      <div v-for="(menu,index) in menus" :key="index" >
        <v-btn
            :title="menu.title"
            :to="menu.path"
            :aria-labelledby="menu.subtitle"
            class="nav-btn mx-0"
            depressed
            :ripple="false"
            color="transparent"
        >
          {{ menu.title }}
        </v-btn>
      </div>
    </v-card>

    <div v-if="!hasRole($auth.user, ['LearningPlusBusiness'])" class="d-flex justify-end top-header">
      <a v-if="$auth.isAuthenticated"
         class="mt-1 nav-btn mr-2" :href="portal" target="_blank"
         title="Go to LearningPlus Portal"
         aria-labelledby="Go to LearningPlus Portal"
         @click="matomoEvent('Page Navigation', 'Navigate to page Click', 'LearningPlus Portal');">
        My Learning
      </a>

      <div class="text-center">
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" fab plain small class="ma-1">
              <v-icon v-if="!$auth.isAuthenticated" class="pp-scale user-hover">
                mdi-account
              </v-icon>

              <v-avatar v-else size="35" class="mb-1 pp-scale-1">
                <v-img :src="$auth.user.picture" />
              </v-avatar>
            </v-btn>
          </template>

          <v-card>
            <v-list color="accent">
              <v-list-item v-if="!$auth.isAuthenticated">
                <v-card flat class="pp-account"
                        @click="login"
                        title="Login as LearningPlus User"
                        aria-labelledby="Login as LearningPlus User"
                >
                  <v-icon class="mr-2">
                    mdi-login-variant
                  </v-icon>
                  Log in
                </v-card>
              </v-list-item>

              <v-list-item v-if="$auth.isAuthenticated">
                <v-card flat class="pp-account pl-0"
                        to="/profile"
                        title="View LearningPlus Profile"
                        aria-labelledby="View LearningPlus Profile"
                        @click="menu = false"
                >
                  <v-list-item>
                    <v-list-item-avatar> <v-img :src="$auth.user.picture" /></v-list-item-avatar>
                    <v-list-item-content class="profile">
                      <span class="text-h71">LearningPlus Profile</span>
                      <span class="text-subtitle-2">{{ $auth.user.name }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-list-item>

              <v-list-item v-if="$auth.isAuthenticated">
                <v-card flat class="pp-account"
                        @click="logout"
                        title="Sign out"
                        aria-labelledby="Sign out"
                >
                  <v-icon class="mr-2">
                    mdi-logout-variant
                  </v-icon>
                  Sign Out
                </v-card>
              </v-list-item>

              <v-list-item v-if="!$auth.isAuthenticated">
                <v-expansion-panels flat class="pp-account py-6">
                  <v-expansion-panel class="transparent">
                    <v-expansion-panel-header class="pa-0" hide-actions>
                      <v-row no-gutters>
                        <v-col cols="1">
                          <v-icon class="mr-2">
                            mdi-account-plus-outline
                          </v-icon>
                        </v-col>

                        <v-col>
                          <div class="profile transparent">
                            Sign Up
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <SignUpRedirect />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item>

              <v-list-item>
                <v-card flat class="pp-account"
                        @click="navigateContact()"
                        title="Contact LearningPlus Support"
                        aria-labelledby="Contact LearningPlus Support"
                >
                  <v-icon class="mr-2">
                    mdi-message-processing-outline
                  </v-icon>
                  Support
                </v-card>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>
    <Basket v-if="!hasRole($auth.user, ['LearningPlusBusiness'])" class="pp-basket" />
  </v-container>
</template>

<script>
import router from "@/router";
import Basket from "@/components/Basket";
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";
import SignUpRedirect from "@/components/common/SignUpRedirect";
import { hasRoleMixin } from "@/mixin/hasRoleMixin";

export default {
  name: "MediumHeader",
  mixins: [hasRoleMixin],
  components: {SignUpRedirect, LearningPlusLogo, Basket},
  data: () => ({
    menu: false,
    portal: process.env.VUE_APP_LEARNER_PORTAL,
    pps: process.env.VUE_APP_PPS,
    menus: [
      {
        title: 'Learners',
        subtitle: 'Learn More About Learners',
        path: '/learners',
      },
      {
        title: 'Employers',
        subtitle: 'Learn More About Employers',
        path: '/employers',
      },
      {
        title: 'Education Sector',
        subtitle: 'Learn More About Education Sector',
        path: '/education-sector',
      },
      {
        title: 'About Us',
        subtitle: 'Learn More About Us',
        path: '/about',
      },
      {
        title: 'Contact Us',
        subtitle: 'Contact Us',
        path: '/#contact',
      },
      {
        title: 'FAQs',
        subtitle: 'Frequently Asked Questions',
        path: '/faqs',
      },
    ],
  }),
  methods: {
    navigateContact(){
      router.push({ path: '/#contact' }).catch(err => {
        if(err.name === 'NavigationDuplicated'){
          window.location.href = '#contact';
        }
      });
      this.menu = false;
    },
    login() {
      this.$auth.loginWithRedirect();
      this.menu = false;
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
      this.menu = false;
    },
  },
};
</script>

<style scoped>
.bs-page-logo {
  position: absolute;
  left: 0;
}

.user-hover:hover {
  color: var(--primary);
}

.nav-btn:active  {
  color: var(--primary);
}

.nav-btn:hover  {
  color: var(--primary);
  border-bottom: 5px solid var(--primary) !important;
}

.nav-btn {
  color: slategrey;
  text-decoration: none;
  margin-right: 35px;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.4px;
  text-align: center;
  border-bottom: 5px solid transparent;
}

.top-header {
  position: absolute;
  right: 70px;
  top: 10px;
}

.menu-item a {
  color: slategrey;
  text-decoration: none;
  margin-right: 35px;
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.4px;
  text-align: center;
  border-bottom: 5px solid transparent;
}

.nav .btn:hover {
  color: #b11116;
  border-bottom: 5px solid #b11116;
}

.nav .btn:active {
  color: #b11116;
}

.pp-scale {
  transform: scale(1.3);
  margin-bottom: 5px;
  color: lightslategrey;
}

.pp-header {
  position: fixed;
  background-color: #ECF0F3;
  z-index: 100;
  top: 0;
  left: 0;
  padding-bottom: 8px;
}

.pp-account {
  background-color: #ECF0F3;
  padding: 20px;
  font-size: 25px;
  font-weight: 700;
  color: lightslategrey;
  margin-right: 20px;
  cursor: pointer;
  letter-spacing: 0.1px;
  width: 350px;
}

.pp-account:hover {
  color: #b11116;
  margin: 10px;
  border-radius: 10px;
  box-shadow:
      6px 6px 6px rgba(0, 0, 0, 0.1),
      -8px -8px 8px rgba(255, 255, 255, 0.8) !important;
}

.pp-basket {
  position: absolute;
  top: 5px;
  right: 5px;
}

.home-nav {
  color: lightslategrey;
  margin-right: 10px;
  padding-bottom: 2px;
}

.home-nav:hover {
  color: var(--primary);
  transform: scale(1.2);
}

.profile {
  color: lightslategray;
  width: 100%;
  margin-left: 10px;
  font-size: 25px;
  font-weight: 700;
  margin-right: 20px;
  cursor: pointer;
  letter-spacing: 0.1px;
  height: 100%;
}

.profile:hover {
  color: var(--primary);
}
</style>

import axios from './common';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe(`${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`);

const state = {
    basket: [],
    showBusinessOffer: false,
    basketVisible: false,
    basketValue: 0,
    stripeSessionId: null,
    stripePaymentIntentId: null,
    basketLoading: false,
};

const getters = {
    basket: state => {
        return state.basket;
    },
    showBusinessOffer: state => {
        return state.showBusinessOffer;
    },
    basketLoading: state => {
        return state.basketLoading;
    },
    basketVisible: state => {
        return state.basketVisible;
    },
    basketValue: state => {
        return state.basketValue;
    },
    stripePaymentIntentId: state => {
      return state.stripePaymentIntentId;
    },
    stripeSessionId: state => {
        return state.stripeSessionId;
    },
};

const actions = {
    addToBasketItem(context, item) {
        context.commit('addToBasketItem', item);
        context.commit('checkForBusinessOffer');
        context.commit('updateBasketValue');
    },
    replaceBasketItem(context, item){
        context.commit('replaceBasketItem', item);
        context.commit('checkForBusinessOffer');
        context.commit('updateBasketValue');
    },
    deleteBasketItem(context, item) {
        context.commit('deleteBasketItem', item);
        context.commit('checkForBusinessOffer');
        context.commit('updateBasketValue');
    },
    hideBasket(context) {
        context.commit('hideBasket');
    },
    showBasket(context) {
        context.commit('showBasket');
    },
    async checkout(context, basket) {

        //basket loading
        context.commit('setBasketLoading', true);

        const stripe = await stripePromise;

        const windowOrigin = window.location.origin;
        const slashPad = (input) => {
            return (input.startsWith('/') === true) ? input : '/' + input;
        };

        const data = {
            items: basket,
            cancel_url: `${windowOrigin}${slashPad(process.env.VUE_APP_PAYMENT_CANCEL)}`,
            success_url: `${windowOrigin}${slashPad(process.env.VUE_APP_PAYMENT_SUCCESS)}`,
        };

        const apiResponse = await axios.post(`${process.env.VUE_APP_SERVICE_URI_PAYMENT}/api/checkout`, data);
        context.commit('setStripeSessionId', apiResponse.data.id);
        context.commit('setStripePaymentIntentId', apiResponse.data.payment_intent);
        context.commit('setBasketLoading', false);

        const stripeResult = await stripe.redirectToCheckout({
            sessionId: apiResponse.data.id,
        });

        // If we get here then something has gone wrong with Stripe, so we may as well throw it
        if (stripeResult.error) {
            throw stripeResult.error;
        }
    },
    async checkoutRegisterSuccess(context, id){

        context.commit('closeAndClearBasket');

        await axios.post(`${process.env.VUE_APP_SERVICE_URI_PAYMENT}/api/checkout-success`, {
            id: id,
        });
    },

    async businessRegisterSuccess(context){
        context.commit('closeAndClearBasket');
    },
};

const mutations = {

    addToBasketItem (state, item) {

        let currentItems = state.basket;
        let hasItem = false;

        currentItems.forEach((value, index) => {
            if(value._id === item._id){
                hasItem = true;
                item.quantity = currentItems[index].quantity + item.amountToAddToBasket;
                item.amountToReplaceBasket = item.quantity;

                currentItems[index] = item;
            }
        });

        if(hasItem === false){
            item.quantity = item.amountToAddToBasket;
            item.amountToReplaceBasket = item.quantity;
            currentItems.push(item);

            //Doing it this way seems to force vuex to recognise the change immediately
            state.basket = [];
            state.basket = currentItems;
        }
    },

    checkForBusinessOffer (state) {
        state.showBusinessOffer = false;
        state.basket.forEach((item) => {
            if (item.quantity >= 10) {
                state.showBusinessOffer = true;
            }
        });
    },

    closeAndClearBasket (state) {
        state.basketVisible = false;
        state.basket = [];
        state.basketValue = 0;
    },

    replaceBasketItem(state, item){

        let currentItems = state.basket;

        currentItems.forEach((value, index) => {
            if(value._id === item._id){

                item.quantity = item.amountToReplaceBasket;
                currentItems[index] = item;

                if(item.amountToReplaceBasket <= 0){
                    currentItems.splice(index, 1);
                }
            }
        });

        //Doing it this way seems to force vuex to recognise the change immediately
        state.basket = [];
        state.basket = currentItems;
    },
    deleteBasketItem(state, item) {

        let currentItems = state.basket;

        currentItems.forEach((value, index) => {
            if(value._id === item._id){
                currentItems.splice(index, 1);
            }
        });

        //Doing it this way seems to force vuex to recognise the change immediately
        state.basket = [];
        state.basket = currentItems;

    },
    updateBasketValue(state) {
        state.basketValue = 0;
        state.basket.forEach((item) => {
            if(item.priceInUnits){
                state.basketValue = state.basketValue + (item.priceInUnits * item.quantity);
            }
        });
    },
    hideBasket (state) {
        state.basketVisible = false;
    },
    showBasket (state) {
        state.basketVisible = true;
    },
    setBasketLoading (state, isLoading) {
        state.basketLoading = isLoading;
    },
    setStripeSessionId (state, id) {
        state.stripeSessionId = id;
    },
    setStripePaymentIntentId (state, id) {
        state.stripePaymentIntentId = id;
    },
    clearBasket (state) {
        state.basket = [];
        state.basketValue = 0;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

<template>
  <v-img src="/images/logo/logo.svg"
         alt="LearningPlus Logo"
         contain
         class="my-1"
         :height="height"
         :width="width"
  />
</template>

<script>
export default {
  name: "LearningPlusLogo",
  props: {
    height: Number,
    width: Number,
  },
};
</script>

<template>

  <div>
    <Loading title="Loading Trusted by Companies" :loading="trustedLogosLoading"/>
    <div
        v-if="!trustedLogosLoading && trustedLogoImages && trustedLogoImages.length > 0"
        class="pa-0 ma-0 pa-md-10 pb-md-15 white"
    >

      <div class="pp-title py-5 pb-md-15 pb-10">
        We have been trusted by
      </div>
      <v-row>

        <div class="hidden-sm-and-down">
          <LogosDesktop></LogosDesktop>
        </div>
      </v-row>

      <div class="hidden-md-and-up">
        <LogosMobile></LogosMobile>
      </div>

    </div>
  </div>
</template>

<script>
import LogosDesktop from "@/components/companies/LogosDesktop";
import LogosMobile from "@/components/companies/LogosMobile";
import Loading from "@/components/common/Loading";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "CompanyLogos",
  components: {Loading, LogosMobile, LogosDesktop},
  computed: {
    ...mapGetters(['trustedLogosLoading', 'trustedLogoImages']),
  },
  methods: {
    ...mapActions(['getTrustedLogos']),
  },
  beforeMount() {
    this.getTrustedLogos(1);
  },
};
</script>

const state = {
    reveal: false,
};

const getters = {
    reveal: state => {
        return state.reveal;
    },
};

const actions = {
    setReveal(context, isVisible) {
        context.commit('setReveal', isVisible);
    },
};

const mutations = {
    setReveal(state, isVisible){
        state.reveal = isVisible ;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
import axios from "axios";
import axiosMock from "axios-mock-adapter";
import * as faker from 'faker';

const mock = new axiosMock(axios);

const docs = [];
for (let i = 0; i < 5; i++) {
    let image = {};
    if (faker.datatype.boolean()) {
        image = {
            url: faker.internet.avatar(),
            alt: faker.lorem.paragraph(),
            ref: `${process.env.VUE_APP_SERVICE_URI_REVIEW}/api/reviews/${faker.datatype.number()}`,
        };
    }
    docs.push({
        uuid: faker.datatype.uuid(),
        title: faker.lorem.words(),
        author: `${faker.name.firstName()} ${faker.name.lastName()}`,
        review: faker.lorem.paragraph(),
        image: image,
    });
}

mock.onGet(`${process.env.VUE_APP_SERVICE_URI_REVIEW}/api/reviews`, { params: {
        page: 1,
    }}).reply(200, {
    docs: docs,
});
<template>
  <div v-if="loading">
    <v-progress-linear indeterminate
                       color="red darken-3"
                       class="pp-full-width"
    />
    <p class="font-weight-light pp-center">{{title}}</p>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ['title', 'loading'],
};
</script>

<style scoped>

</style>

import axios from './common';
import {resultsPerPage} from "@/utility";

if(process.env.VUE_APP_FAKE_SERVICES === 'true') {
    require('./fake/courses.js');
}

const state = {
    courses: [],
    coursesLoading: false,
    popularCourses: [],
    popularCoursesLoading: false,
    courseSelected: null,
    courseSelectedLoading: false,
};

const getters = {
    courses: state => {
        return state.courses;
    },
    coursesLoading: state => {
        return state.coursesLoading;
    },
    popularCourses: state => {
        return state.popularCourses;
    },
    popularCoursesLoading: state => {
        return state.popularCoursesLoading;
    },
    courseSelected: state => {
      return state.courseSelected;
    },
    courseSelectedLoading: state => {
        return state.courseSelectedLoading;
    },
};

const actions = {
    getCourses(context) {
        context.commit('setCoursesLoading', true);

        context.dispatch('retrieveCoursesFromApi', {})
            .then(response => {
                context.commit('setCoursesLoading', false);
                context.commit('setCourses', response.docs);
            });
    },
    retrieveCoursesFromApi(context, {searchCriteria = {}, inputOptions = {}}) {

        const options = {
            tags: 'learning_plus',
            page: inputOptions.page ?? 1,
            limit: inputOptions.limit ?? resultsPerPage,
        };

        if (searchCriteria) {
            Object.assign(options, searchCriteria);
        }

        return axios.get(`${process.env.VUE_APP_SERVICE_URI_COURSE}/api/courses`, { params: options })
            .then(response => {
                return response.data;
            });
    },
    getPopularCourses(context) {

        context.commit('setPopularCoursesLoading', true);
        context.commit('clearPopularCourses');

        context.dispatch('retrieveCoursesFromApi', {
            searchCriteria: {
                popular: true,
            },
        }).then(response => {
            context.commit('setPopularCoursesLoading', false);
            context.commit('setPopularCourses', response.docs);
        });
    },
    setCourseSelected(context, course) {
        context.commit('setCourseSelected', course);
    },
    async getCourseBySlug(context, payload) {

        context.commit('setCourseSelectedLoading', true);

        await context.dispatch('retrieveCoursesFromApi', {
            searchCriteria: {
                slug: payload,
            },
        }).then(response => {
            context.commit('setCourseSelectedLoading', false);
            context.commit('setCourseSelected', response.docs[0]);
        });
    },
};

const mutations = {
    setCourses(state, courses){
        state.courses.push(...courses);
    },
    setCoursesLoading(state, loading){
        state.coursesLoading = loading;
    },
    clearPopularCourses(state){
        state.popularCourses = [];
    },
    setPopularCourses(state, courses){
        state.popularCourses.push(...courses);
    },
    setPopularCoursesLoading(state, loading){
        state.popularCoursesLoading = loading;
    },
    setCourseSelected (state, course) {
        state.courseSelected = course;
    },
    setCourseSelectedLoading(state, loading) {
        state.courseSelectedLoading = loading;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

<template>
  <router-link
    title="Browse Courses"
    aria-labelledby="Browse Courses"
    :to="{ name: 'Home' }"
    @click="matomoEvent('Browse Courses', 'Browse Courses Click');"
>
    <v-btn
        color="primary"
        rounded
        height="50"
        class="px-8 pp-scale-1"
        elevation="3"
    >
      <v-icon class="mr-2">
        mdi-magnify
      </v-icon>
      Browse Courses
    </v-btn>
  </router-link>
</template>

<script>

export default {
  name: "BrowseCourseButton",
};
</script>

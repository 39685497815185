<template>
  <div class="ma-10 mt-5">
    <v-divider/>

    <v-row class="mt-5">
      <div class="text-h6">
        Total:
      </div>

      <v-spacer />

      <div class="text-h5 primary--text font-weight-bold">
        &pound;{{ unitsToDecimal(basketValue) }}
        <span class="text-caption">
          inc VAT
        </span>
      </div>
    </v-row>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {unitsToDecimal} from "@/utility";

export default {
  name: "Total",
  computed: {
    ...mapState(['basketValue']),
    ...mapGetters(['basketValue']),
  },
  methods: {
    unitsToDecimal: unitsToDecimal,
  },
};
</script>

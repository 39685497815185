<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AppForAutoCheckout",
  computed: {
    ...mapGetters(['auth0Token']),
  },
  methods: {
    ...mapActions([
      'getAuth',
    ]),
  },
  async created() {
    try {
      await this.getAuth();
    } catch (err) {
      //TODO - Do something with the error and remove console.log
      console.error('error', err);
    }
  },
};
</script>

<style>
</style>

<template>
  <div class="mx-5 mx-sm-10 pb-16" >
    <div v-if="$auth.user">
      <v-sheet
          v-if="errorMessage"
          class="ma-md-10 mb-10 rounded-xl grey lighten-3" >
        <div class="pa-sm-10 pa-6">
          Your purchase of LearningPlus courses failed because something went wrong.
          <span class="font-weight-bold">Your account has not been charged.</span>
          Please try again, or if the error persists, please contact LearningPlus support at
          <a :href="supportEmail.href"
             :title="supportEmail.title"
             :aria-labelledby="supportEmail.linkText"
             @click="matomoEvent('Contact Support', 'Contact Support Click', 'Email Support');">
            {{ supportEmail.email }}
          </a>.
        </div>
      </v-sheet>

      <v-btn
          title="Checkout Now"
          aria-labelledby="Checkout Now"
          depressed
          block
          elevation="4"
          large
          rounded
          color="primary"
          dark
          class="pa-6 pp-scale-1"
          @click="checkoutBasket"
      >
        <div v-if="basketLoading && !errorMessage" >
          <v-progress-circular indeterminate size="22" class="mr-3 mb-1"/>
          <span>Loading... Please wait</span>
        </div>

        <span v-else>
        <v-icon class="mr-2">
          mdi-shopping-outline
        </v-icon>Checkout</span>
      </v-btn>
    </div>

    <div v-else>
      <v-btn
          title="View Account"
          aria-labelledby="View LearningPlus Account"
          depressed
          block
          elevation="4"
          large
          rounded
          @click="login"
          color="primary"
          dark
          class="pa-6 pp-scale-1 my-2"
      >
        <v-icon class="mr-2" large>
          mdi-account
        </v-icon>
        Login to Checkout
      </v-btn>

      <v-divider class="my-8"/>

      <template>
        <v-card class="mx-auto rounded-xl" color="accent">
          <v-card-text class="text-center pt-6 pp-pointer pp-scale-1" @click="reveal = true">
            <p class="primary--text text-h6 my-0 py-0">New customer?</p>
            <p class="text-h6 my-0 py-0">
              Create an account to checkout
            </p>
            <div class="pp-hr pp-center" />
            <LearningPlusLogo :height="20" />
          </v-card-text>

          <v-expand-transition>
            <v-card
                v-if="reveal"
                class="transition-fast-in-fast-out v-card--reveal rounded-xl"
                color="accent"
            >
              <v-row>
                <v-col class="mt-3">
                  <SignUpRedirect class="pa-6 pt-2 pt-sm-6" :redirect-uri="redirectUri()" />
                </v-col>

                <v-col class="ma-2 hidden-xs-only" cols="1">
                  <v-btn @click="reveal = false" fab x-small plain>
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <div
                  @click="reveal = false"
                  class="text-center cancel hidden-sm-and-up">
                Cancel
              </div>
            </v-card>
          </v-expand-transition>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SignUpRedirect from "@/components/common/SignUpRedirect";
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";
import {hasRoleMixin} from "@/mixin/hasRoleMixin";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";

export default {
  name: "CheckOut",
  mixins: [hasRoleMixin, matomoEcommerce],
  components: {LearningPlusLogo, SignUpRedirect},
  computed: {
    ...mapGetters(['basket', 'basketValue', 'basketLoading', 'supportEmail']),
  },
  methods: {
    ...mapActions(['checkout']),
    login() {
      this.$auth.loginWithRedirect({
        redirect_uri: window.location.origin + '/checkout',
      });
      this.matomoEvent('Sign In', 'Sign In Click', 'Sign In to Checkout');
    },
    async checkoutBasket() {
      await this.updateCartTracking();
      try {
        await this.checkout(this.basket);
      } catch (stripeError) {
        console.error(stripeError.message);
        this.errorMessage = true;
      }
    },
    updateCartTracking() {
      for (const item of this.basket) {
        this.matomoAddEcommerceItem(item._id, item.name, "", item.priceInUnits, item.quantity);
      }
      this.matomoTrackEcommerceCartUpdate(this.basketValue);
    },
  },
  data: () => ({
    reveal: false,
    errorMessage: false,
    redirectUri: () => {
      return window.location.origin + '/checkout';
    },
  }),
};
</script>

<style scoped>
.cancel {
  bottom: 2px;
  position:relative;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
